import { html, render } from 'lit-html';

export const promptContent = [
    {
        intro: html`
            Ready when you are. <br />
            Start scrolling.
        `,
        fast: 'This is a marathon, not a sprint. You can slow down!',
        slow: html`
            Are you ghosting? <br />Come back!
        `,
        just_right: html`
            That’s perfect. Keep going&mdash;we’re saving something special for the end.
        `,
    },
    {
        intro: 'Scroll a little harder now.',
        fast: 'Nobody likes a jackrabbit. Slow down!',
        slow: 'You can go harder than that, you’re not gonna hurt me.',
        just_right: 'Hey, you’re good at this. Don’t forget yours is coming...',
    },
    {
        intro: 'Scroll a little harder now.',
        fast: 'Nobody likes a jackrabbit. Slow down!',
        slow: 'You can go harder than that, you’re not gonna hurt me.',
        just_right: 'Hey, you’re good at this. Don’t forget yours is coming...',
    },
    {
        intro: 'You’re getting closer. In fact, we both are...',
        fast: 'All things in due time! Cool it just a bit.',
        slow: 'Don’t be shy—I know you can do better than that.',
        just_right: 'Where’d you learn how to do that? Love it.',
    },
    {
        intro: 'Keep this going!',
        fast: 'Relax, Casanova! Not so fast.',
        slow: 'Hmmm. Try something a little different. I’m not feeling it.',
        just_right: 'Don’t forget we have something for you, too.',
    },
    {
        intro: 'Now I need you to click right there.',
        fast: 'Wait wait wait. Let’s enjoy this.',
        continue: 'Keep clicking!',
        slow: 'Now’s no time for stage fright. Gimme a click!',
        just_right: 'That’s great. Now click, click, click, yes keep clicking. As hard as you can. Riiiight there.',
    },
];
