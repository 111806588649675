import DomUtils from '@/helpers/utilities';
import fullpage from '@/libraries/fullpage';
import store from '@/modules/PubSub/store/index.js';
import { isMobile } from '@/helpers/utilities';
import { isMobileSize } from '@/helpers/utilities';

export let fnAdvanceSection = function () {
    window.fullpage_api.moveSectionDown();
};

const Screenfull = (() => {
    class Screenfull {
        constructor(node) {
            this.node = document.querySelector(node);
            this.node.utils = new DomUtils(this.node);
            this.navLinks = null;
            this.selector = node;
            this.store = store;
            this.currentPage = this.store.state.currentPage;
            this.isLockedState = this.store.state.isLockedState;

            this.eventCounter = 0;
            this.hasRebuilt = false;
            this.hasHiddenAddressBar = false;
        }

        initPlugin() {
            if (isMobileSize || isMobile) {
                new fullpage(this.selector, {
                    //Custom selectors
                    sectionSelector: '.screenfull',

                    //events
                    afterRender: this.onAfterRender.bind(this),
                    onLeave: this.onLeave.bind(this),
                    scrollingSpeed: 800,
                    licenseKey: '640F1BF8-11CF4858-B488E496-26FC38B3',
                    keyboardScrolling: false,
                    normalScrollElements: '.info-section',
                });
            } else {
                new fullpage(this.selector, {
                    //Custom selectors
                    sectionSelector: '.screenfull',

                    //events
                    afterRender: this.onAfterRender.bind(this),
                    onLeave: this.onLeave.bind(this),
                    scrollingSpeed: 800,
                    licenseKey: '640F1BF8-11CF4858-B488E496-26FC38B3',
                    keyboardScrolling: false,
                    //navigation: true,
                    // navigationPosition: 'left'
                });
            }
        }

        onLeave() {
            this.toggleScrollLock(true);
            window.dispatchEvent(new Event('fullpageOnLeave'));
        }

        onAfterRender() {
            this.navLinks = document.querySelectorAll('#fp-nav a');
        }

        toggleScrollLock(bool) {
            window.fullpage_api.setAllowScrolling(!bool);
        }

        addEventListeners() {
            this.navLinks.forEach((navLink, index) => {
                navLink.addEventListener('click', this.onNavLinkClick.bind(this, navLink, index));
            });
        }

        init() {
            this.initPlugin();
            this.addEventListeners();
            this.toggleScrollLock(true);
        }
    }

    return {
        init({ selector }) {
            return new Screenfull(selector).init();
        },
    };
})();

export default Object.create(Screenfull);
