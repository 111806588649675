import { html } from 'lit-html';

/*———— -Components- ————*/
import { intro } from '@/components/Intro';
import { OHsvg } from '@/components/OH';
import { theWave } from '@/components/TheWave';
import { readyScreen } from '@/components/ReadyScreen';
import { scrollIndicator } from '@/components/ScrollIndicator';
import { nicknameForm } from '@/components/NicknameForm';
import { challengePrompt } from '../components/ChallengePrompt/ChallengePrompt';
import { infoSection } from '../components/infoSection/InfoSection';
import { infoSectionContent } from '../components/infoSection/InfoSection-content';
import { conversionForm } from '../components/ConversionForm';
import { theNav } from '../components/TheNav';
import { thankYou } from '../components/ThankYou';
import { orgasm } from '../components/orgasm/orgasm';
import { cursorFeedback } from '../components/ChallengePrompt/CursorFeedback';
import { ohVideo } from '../components/orgasm/o-video';
import { climaxOHsvg } from '../components/OH';
import { isMobile, isMobileSize } from '../helpers/utilities';

//prettier-ignore
let introParts = [
    intro,
    nicknameForm,
    readyScreen,
]

//prettier-ignore
let finaleParts = [
    challengePrompt(4 , (isMobile || isMobileSize)),
    orgasm(isMobile),
    conversionForm,
    thankYou
]

//prettier-ignore
export const page = html`
    ${ohVideo}
    ${theNav}
    ${OHsvg}
    ${climaxOHsvg}
    ${cursorFeedback}
    ${theWave}
    ${scrollIndicator(isMobile || isMobileSize)}
    <div id="fullpage" class="animated reveal fade-in screenfull__container">
        ${
            //prettier-ignore
            introParts.map((templatePart) =>
                html`
                        <div class="screenfull"> 
                            ${templatePart}
                        </div>`
            )
        }
 
        ${
    //prettier-ignore
    infoSectionContent.map((content, index) => html`
                
        ${index == 2 ?
            `` :
            html`
                    <div class="screenfull"> 
                     ${challengePrompt(index , (isMobile || isMobileSize))}
                    </div>
                    `
        }
                
                <div class="screenfull"> 
                    ${infoSection(content, index)}
                </div>    
            `
    )
    }

        ${
    //prettier-ignore
    finaleParts.map((templatePart) =>
        html`
                <div class="screenfull"> 
                    ${templatePart}
                </div>`
    )
    }
        

    </div>
`;

//prettier-ignore
export const mobilePage = html`
    ${ohVideo}
    ${theNav}
    ${OHsvg}
    ${climaxOHsvg}
    ${cursorFeedback}
    ${theWave}
    ${scrollIndicator(isMobile || isMobileSize)}
    <div id="fullpage" class="animated reveal fade-in screenfull__container">
        ${
    //prettier-ignore
    introParts.map((templatePart) =>
        html`
                <div class="screenfull"> 
                    ${templatePart}
                </div>`
    )
    }
 
        ${
    

    /*exclusion ternary below, 
    prints html only for odd numbers less than 4
    purpose: for mobile only, to bring multiple non-adjacent content 
    sections together in the same '.screenful'*/
   
    //prettier-ignore
    infoSectionContent.map((content, index) => html`
        
        ${(index > 0 && index % 2 !== 0) ? '' : html`
            
            <div class="screenfull"> 
                ${challengePrompt(index , (isMobile || isMobileSize))}
            </div>
            
            <div class="screenfull"> 
                <div class="info-section__scroller"> 
                    <span class="ios-fix-hidden-text">OH</span>
                    ${infoSection(content, index)}
                    ${infoSection(infoSectionContent[index+1], index+1)}
                    <div data-js="check-finished"></div>
                </div>
            </div>    
            `
        }
    `

    )}



        ${
    //prettier-ignore
    finaleParts.map((templatePart) =>
        html`
                <div class="screenfull"> 
                    ${templatePart}
                </div>`
    )
    }
        

    </div>
`;
