import throttle from 'lodash.throttle';
import { isMobile , isMobileSize } from '../helpers/utilities';

const ParallaxMousemove = (() => {
    class ParallaxMousemove {
        constructor() {
            this.node = document.querySelector('.screenfull__container');
            this.layerTwo = document.querySelectorAll('.fp-section.active .info-section__image-circle');
            this.layerOne = document.querySelectorAll('.fp-section.active .info-section__image img');
            this.isInfoSection = false;
            this.isMoving = true;
            this.loopIsRunning = false;
            this.xCoord = null;
            this.yCoord = null;
            this.lastX = null;
            this.lastY = null;
        }
        
        getRandomInt(min, max) {
            return Math.random() * (max - min) + min;
        }
        
        randomMovementLoop(){
            if (!(this.layerOne.length && this.layerTwo.length)) return;
            if(this.isMovingMouse) return;

            this.loopIsRunning = true;

            /*pick up where the mouse left off, if mouse coords are not 
            set yet, start from (0,0)*/
            let randX = this.xCoord ? this.xCoord : 0;
            let randY = this.yCoord ? this.yCoord : 0;
            let forward = true;
            
            let pace = 75; //lower pace will go faster

            let xMin = window.innerWidth / pace;
            let xMax = window.innerWidth / pace + ((window.innerWidth / pace)/2);
            let yMin = window.innerHeight / pace;
            let yMax = window.innerHeight / pace + ((window.innerHeight / pace)/2);

            this.animationLoop = setInterval(()=>{

                //flip flop the directions when bounds are reached
                if(forward && randX > (window.innerWidth * .7) ){
                    forward = false;
                } 
                if(!forward && randX < 10){
                    forward = true;
                }             
                
                randX += forward ? this.getRandomInt(xMin, xMax) : -this.getRandomInt(xMin, xMax);
                randY += forward ? this.getRandomInt(yMin, yMax) : -this.getRandomInt(yMin, yMax);
                
                this.lastX = randX;
                this.lastY = randY;

                this.doAnimation(randX, randY);

                if(this.isMovingMouse){
                    this.loopIsRunning = false;
                    clearInterval(this.animationLoop);
                }

                if(!this.isInfoSection){
                    clearInterval(this.animationLoop);
                }

            }, 82); //around 12 fps
        }

        doAnimation(x,y){
            let layerOne = this.layerOne[0];
            let layerTwo = this.layerTwo[0];
            let pageX = x;
            let pageY = y;

            if ((typeof layerOne !== 'undefined') && (typeof layerTwo !== 'undefined')  ){
            
                layerTwo.style.webkitTransform = 'translateX(' + pageX / 100 + '%) translateY(' + pageY / 100 + '%)';
                layerTwo.style.transform = 'translateX(' + pageX / 100 + '%) translateY(' + pageY / 100 + '%)';
                layerOne.style.webkitTransform = 'translateY(' + pageY / 250 + '%)';
                layerOne.style.transform = ' translateY(' + pageY / 250 + '%)';
            }
        }

        onFullpageLeave(){
            
            clearInterval(this.animationLoop);

            setTimeout(()=>{
                this.layerTwo = document.querySelectorAll('.fp-section.active .info-section__image-circle');
                this.layerOne = document.querySelectorAll('.fp-section.active .info-section__image img');

                if (!(this.layerOne.length) && !(this.layerTwo.length)){
                    this.isInfoSection = false;
                    this.checkMouseMove(true);
                } else{
                    this.isInfoSection = true;
                    this.randomMovementLoop();
                    this.checkMouseMove();
                }

            }, 300);
        }

        onMouseMove(e){
            if (!(this.layerOne.length) && !(this.layerTwo.length)) return;
            this.isMovingMouse = true;
            
            let xCoord = e.clientX;
            let yCoord = e.clientY;
        
            this.doAnimation(xCoord, yCoord);
            
            this.xCoord = xCoord;
            this.yCoord = yCoord;
        }

        checkMouseMove(stop){
            if(stop) return false;

            let mouseChecker = setInterval(()=>{
                /* every 250 get coordinates, then wait 300ms and check again if 
                coordinates are the same or not to determine if the mouse has moved
                in the last second or so */

                if(stop){
                    clearInterval(mouseChecker);
                }

                let mousePosition = this.xCoord + this.yCoord;

                setTimeout(()=>{
                    if (mousePosition == this.xCoord + this.yCoord){
                        this.isMovingMouse = false;
                        
                        if(!this.loopIsRunning){
                            this.randomMovementLoop();
                        }
                    }
                }, 300);

            }, 250);
        }

        addEventListeners(){
            this.throttledMousemove = throttle(this.onMouseMove.bind(this), 50); 
            window.addEventListener('fullpageOnLeave', this.onFullpageLeave.bind(this));
            this.node.addEventListener('mousemove', this.throttledMousemove.bind(this));
        }

        shouldInit(){
            if(!isMobile && !isMobile){
                return true;
            }
        }

        init() {
            if(!this.shouldInit()) return;
            this.addEventListeners();
        }
    }

    return {
        init() {
            return new ParallaxMousemove().init();
        },
    };
})();

export default Object.create(ParallaxMousemove);