export default {
    changePage(context, payload) {
        context.commit('changePage', payload);
    },

    toggleScrollLock(context, payload) {
        context.commit('toggleScrollLock', payload);
    },

    toggleChallenge(context, payload) {
        context.commit('toggleChallenge', payload);
    },

    setLoadingState(context, payload) {
        context.commit('setLoadingState', payload);
    },

    setNickname(context, payload) {
        context.commit('setNickname', payload);
    },

    setAgeGate(context, payload) {
        context.commit('setAgeGate', payload);
    },

    setChallengePoints(context, payload) {
        context.commit('setChallengePoints', payload);
    },

    setWaveVisibility(context, payload) {
        context.commit('setWaveVisibility', payload);
    },
};
