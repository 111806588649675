import { html, render } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import store from '@/modules/PubSub/store/index.js';
import { oRing } from './o-ring';
import { promptContent } from '@/components/ChallengePrompt/prompt-content';
import { isMobile, isMobileSize } from '@/helpers/utilities';

const Orgasm = (() => {
    class Orgasm {
        constructor() {
            this.node = document.querySelector('.orgasm');
            this.node.utils = new DomUtils(this.node);
            this.clickAnywhere = this.node;
            this.oh = this.node.querySelector('.animated-OH');
            this.oRing = this.node.querySelector('.orgasm__o-ring');
            this.centerPiece = this.node.querySelector('.orgasm__o-ring svg');
            this.svgPieces = ['#Stroke-11', '#Stroke-9', '#Stroke-5', '#Stroke-3', '#Stroke-1'];
            this.ohOhOhPieces = this.node.querySelectorAll('.orgasm__o-ring svg .oh-oh-oh');
            this.prompt = document.querySelector('.orgasm [data-js="prompt-text"]');
            this.logo = document.querySelector('.site-logo');
            this.nav = document.querySelector('.site-nav');
            this.hasStartedGradient = false;
            this.store = store;
            this.progress = 0;
            this.clickCount = 0;
            this.justClicked = false;
            this.finished = false;
            this.hasCompletedAnimation = false;
            this.debounceRipple = true;
            this.iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        }

        completeChallenge() {
            if (this.finished) return;

            [this.logo, this.nav].forEach((element) => {
                element.classList.add('fade');
                element.classList.add('fade-out');
            });

            if (this.iOS) {
                window.dispatchEvent(new Event('triggerVideoStart'));

                setTimeout(() => {
                    this.oRing.style.opacity = 0;
                }, 3000);
            } else {

                window.dispatchEvent(new Event('triggerVideoStart'));

                setTimeout(() => {
                    this.oRing.style.opacity = 0;
                }, 3000);

            }

            this.ohOhOhPieces.forEach(function (oh) {
                oh.classList.add('animated');
            });

            setTimeout(() => {
                //hide after O is complete.
                setTimeout(() => {
                    this.oRing.classList.add('hide');
                }, 6000);

                setTimeout(() => {
                    this.enterGateway();
                }, 5000);
            }, 250);
        }

        enterGateway() {
            if (this.hasCompletedAnimation) return;
            this.hasCompletedAnimation = true;

            setTimeout(() => {
                setTimeout(() => {
                    store.dispatch('setChallengePoints', this.store.state.currentPage - 2);
                }, 600);
            }, 2750);
        }

        checkProgess() {
            if (this.clickCount == 1) {
                let msg = 'Keep clicking!';

                if (isMobile || isMobileSize) {
                    msg = this.clickForTap(msg);
                }

                render(msg, this.prompt);
            }

            if (this.clickCount == 5) {
                this.completeChallenge();
            }
        }

        ripple(e) {
            this.clickCount++;

            window.clickCount = this.clickCount;

            if (this.clickCount < 5) {
                window.dispatchEvent(new Event('positiveReinforcement'));
            }

            if (this.clickCount == 5) {
                window.dispatchEvent(new Event('calloutName'));

                setTimeout(() => {
                    this.prompt.classList.add('fade-out');
                }, 2000);
            }

            let interval = 100;

            this.svgPieces.map((element, index) => {
                if (!this.debounceRipple) return;
                element = this.node.querySelector(element);
                element.classList.remove('pulse-now-large');

                setTimeout(() => {
                    element.classList.add('pulse-now-large');
                }, interval);
                interval += 100;
            });

            this.debounceRipple = false;

            setTimeout(() => {
                this.debounceRipple = true;
            }, interval + 500);

            this.checkProgess();
        }

        calloutCenterpiece() {
            setTimeout(() => {
                this.centerPiece.classList.add('flash');
                this.centerPiece.classList.add('pulse-now');

                setTimeout(() => {
                    this.centerPiece.classList.remove('flash');
                    this.centerPiece.classList.remove('pulse-now');
                }, 600);
            }, 1700);
        }

        getContent(key) {
            let dialouge = this;

            if (key == 'intro') {
                dialouge = 'intro';
            }

            if (key == 'too_slow') {
                dialouge = 'slow';
            }

            if (key == 'too_fast') {
                dialouge = 'fast';
            }

            if (key == 'just_right') {
                dialouge = 'just_right';
            }

            return promptContent[5][dialouge];
        }

        clickForTap(text) {
            /*match all instances of 'click' or 'clicking' in uppercase or lowercase
            replace with 'tap' or 'tapping'*/
            let pattern = /clicking/gi;
            text = text.replace(pattern, 'tapping');

            pattern = /click/gi;
            return text.replace(pattern, 'tap');
        }

        updateContent(key) {
            if (this.hasRevealed) return;
            if (this.contentBuffer) return;

            let content = this.getContent(key);

            if (isMobile || isMobileSize) {
                content = this.clickForTap(content);
            }

            render(content, this.prompt);

            setTimeout(() => {
                this.hasRevealed = true;
                let reveal = document.querySelectorAll('.fp-section.active .reveal');
                this.reveal = reveal[0];

                if (reveal.length) {
                    this.reveal.classList.remove('fade-out');
                    this.reveal.classList.add('fade-in');

                    setTimeout(() => {
                        this.hasRevealed = false;
                    }, 800);
                }
            }, 600);
        }

        onFullpageLeave() {
            setTimeout(() => {
                if (document.querySelectorAll('.fp-section.active .orgasm').length) {
                    this.updateContent('intro');
                    this.calloutCenterpiece();
                }
            }, 300);
        }

        addEventListeners() {
            if (isMobile || isMobileSize) {
                this.clickAnywhere.addEventListener('touchend', this.ripple.bind(this));
            } else {
                this.clickAnywhere.addEventListener('click', this.ripple.bind(this));
            }

            window.addEventListener('fullpageOnLeave', this.onFullpageLeave.bind(this));
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }

            this.addEventListeners();
        }
    }

    return {
        init() {
            return new Orgasm().init();
        },
    };
})();

export default Object.create(Orgasm);

//prettier-ignore
export const orgasm = (isMobile) => html`
    <div class="orgasm">
        <div class="orgasm__container">
            <div class="orgasm__o-ring">
                ${oRing}
            </div>
        </div>

         <div class="challenge-prompt__container animated reveal pulse">

         ${isMobile
        ? html`
                    <svg width="76px" height="124px" viewBox="0 0 76 124" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!-- Generator: Sketch 52.6 (67491) - http://www.bohemiancoding.com/sketch -->
                    <title>Group</title>
                    <desc>Created with Sketch.</desc>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                        <g id="mobile_scrollIndicator_tap" transform="translate(2.000000, 2.000000)" stroke="currentColor" stroke-width="5">
                            <g id="Group">
                                <polyline id="Path" points="35.4 46.7 71.3 57.2 71.3 95.3 61.5 110.1 61.5 118.8"></polyline>
                                <polyline id="Path" points="18.9 118.8 18.9 110.1 6.2 95.3"></polyline>
                                <path d="M19.7,77 L19.7,28.6 C19.7,24.5 23,21.2 27.1,21.2 C31.2,21.2 34.5,24.5 34.5,28.6 L34.5,63.8" id="Path"></path>
                                <path d="M46.7,50 L46.7,67.4" id="Path"></path>
                                <path d="M59,71 L59,53.6" id="Path"></path>
                                <path d="M6.2,95.3 L6.2,74.8 C6.2,68.7 11.1,63.8 17.2,63.8 L19.7,63.8" id="Path"></path>
                                <path d="M0.5,37 L0.5,27.5 C0.5,12.8 12.4,0.9 27.1,0.9 C41.8,0.9 53.7,12.8 53.7,27.5 L53.7,35.1" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </svg>
              `
        : html`
                <svg width="84px" height="153px" viewBox="0 0 84 153" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: Sketch 51.2 (57519) - http://www.bohemiancoding.com/sketch -->
                <title>Group 7</title>
                <desc>Created with Sketch.</desc>
                <defs></defs>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <g id="Group-7" transform="translate(2.000000, 2.000000)" stroke="#231F20" stroke-width="5">
                        <path d="M49.2524,148.3379 C32.8034,148.3379 19.4684,135.0029 19.4684,118.5539 L19.4684,65.7399 C19.4684,49.2909 32.8034,35.9559 49.2524,35.9559 C65.7014,35.9559 79.0364,49.2909 79.0364,65.7399 L79.0364,118.5539 C79.0364,135.0029 65.7014,148.3379 49.2524,148.3379 Z" id="Stroke-1"></path>
                        <path d="M34.1254,0.5 C33.0854,4.057 26.6234,26.143 26.6234,26.143 C26.6234,26.143 0.6204,18.535 0.5004,18.5" id="Stroke-3"></path>
                        <path d="M49.2524,53.3086 L49.2524,68.6616" id="Stroke-5"></path>
                        <path d="M49.2524,148.3379 C32.8034,148.3379 19.4684,135.0029 19.4684,118.5539 L19.4684,65.7399 C19.4684,49.2909 32.8034,35.9559 49.2524,35.9559 C65.7014,35.9559 79.0364,49.2909 79.0364,65.7399 L79.0364,118.5539 C79.0364,135.0029 65.7014,148.3379 49.2524,148.3379 Z" id="O-Stroke-1"></path>
                    </g>
                </g>
                </svg>
              `}

            <div data-js="prompt-text" class="challenge-prompt__text">Now click right there and don't stop.</div>

        </div>

    </div>
`;
