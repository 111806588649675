import { html, render } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import store from '@/modules/PubSub/store/index.js';
import { nicknameText } from './NicknameText';

const ReadyScreen = (() => {
    class ReadyScreen {
        constructor() {
            this.node = document.querySelector('.ready-screen');
            this.node.utils = new DomUtils(this.node);
            this.msg = this.node.querySelector('.ready-screen__msg');
            this.store = store;
        }

        onStateChange() {
            if (this.store.state.nickname) {
                this.renderNickname();
            }
        }

        renderNickname() {
            render(nicknameText('Okay', store.state.nickname), this.msg);
        }

        addEventListeners() {
            this.store.events.subscribe('stateChange', () => {
                this.onStateChange();
            });
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }

            this.addEventListeners();
        }
    }

    return {
        init() {
            return new ReadyScreen().init();
        },
    };
})();

export default Object.create(ReadyScreen);

//prettier-ignore
export const readyScreen = html`
    <div class="ready-screen">
        <div class="ready-screen__text">
            <h1 class="ready-screen__msg"> Okay,</h1>
            <hr class="succinct">
            <p>
                If you're ready, scroll down on me. We'll make it worth your while.
            </p>
        </div>
    </div>
`;
