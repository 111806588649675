import { html } from 'lit-html';

//prettier-ignore
export const oRing = html`
<?xml version="1.0" encoding="UTF-8"?>
<svg width="560px" height="547px" viewBox="0 0 560 547" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g id="working" transform="translate(1.000000, 1.000000)" stroke="currentColor" stroke-width="3">
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" id="Stroke-5"class="animated rotator pulse reverse"></path>
            <path d="M367.6061,272.7192 C367.6061,283.5762 346.0541,290.0682 342.1391,299.5092 C338.0811,309.2962 348.5771,329.0932 341.2011,336.4692 C333.8251,343.8442 314.0291,333.3492 304.2421,337.4072 C294.8001,341.3232 288.3091,362.8742 277.4521,362.8742 C266.5941,362.8742 260.1031,341.3232 250.6621,337.4072 C240.8751,333.3492 221.0781,343.8442 213.7031,336.4682 C206.3271,329.0932 216.8221,309.2962 212.7631,299.5092 C208.8491,290.0682 187.2961,283.5762 187.2961,272.7192 C187.2961,261.8632 208.8491,255.3702 212.7641,245.9302 C216.8221,236.1422 206.3271,216.3462 213.7031,208.9702 C221.0791,201.5942 240.8751,212.0902 250.6621,208.0312 C260.1031,204.1162 266.5941,182.5652 277.4521,182.5652 C288.3091,182.5652 294.8001,204.1162 304.2421,208.0312 C314.0291,212.0902 333.8261,201.5952 341.2011,208.9712 C348.5771,216.3462 338.0821,236.1432 342.1391,245.9302 C346.0541,255.3702 367.6061,261.8632 367.6061,272.7192 Z" id="Stroke-9"class="animated rotator pulse"></path>
            <path d="M309.6061,272.719522 C309.6061,276.591779 301.919288,278.907215 300.52295,282.274442 C299.075609,285.765073 302.81915,292.82587 300.1884,295.456594 C297.557649,298.086961 290.497138,294.343815 287.006472,295.791141 C283.638855,297.187821 281.323753,304.8742 277.451457,304.8742 C273.578804,304.8742 271.263701,297.187821 267.896441,295.791141 C264.405775,294.343815 257.344907,298.086961 254.714514,295.456237 C252.083764,292.82587 255.826947,285.765073 254.37925,282.274442 C252.983269,278.907215 245.2961,276.591779 245.2961,272.719522 C245.2961,268.847621 252.983269,266.531828 254.379607,263.164958 C255.826947,259.67397 252.083764,252.61353 254.714514,249.982806 C257.345264,247.352082 264.405775,251.095585 267.896441,249.647902 C271.263701,248.251579 273.578804,240.5652 277.451457,240.5652 C281.323753,240.5652 283.638855,248.251579 287.006472,249.647902 C290.497138,251.095585 297.558006,247.352439 300.1884,249.983163 C302.81915,252.61353 299.075966,259.674327 300.52295,263.164958 C301.919288,266.531828 309.6061,268.847621 309.6061,272.719522 Z"" id="Stroke-11"class="animated rotator pulse"></path>
            <path d="M556.7883,272.4236 C556.7883,292.9316 534.5763,310.6676 530.2153,329.8556 C525.7113,349.6676 537.9213,375.2606 529.2983,393.1356 C520.5313,411.3056 492.8203,417.6656 480.3583,433.2726 C467.8353,448.9556 467.7553,477.3686 452.0723,489.8916 C436.4653,502.3546 408.7663,496.1536 390.5963,504.9196 C372.7213,513.5426 360.3563,539.1496 340.5453,543.6526 C321.3563,548.0136 299.1523,530.4076 278.6433,530.4076 C258.1363,530.4076 235.9323,548.0136 216.7443,543.6526 C196.9313,539.1496 184.5663,513.5426 166.6913,504.9186 C148.5213,496.1536 120.8223,502.3536 105.2153,489.8916 C89.5323,477.3676 89.4523,448.9546 76.9303,433.2726 C64.4673,417.6646 36.7563,411.3046 27.9903,393.1346 C19.3673,375.2596 31.5773,349.6676 27.0733,329.8556 C22.7123,310.6676 0.5003,292.9316 0.5003,272.4236 C0.5003,251.9146 22.7123,234.1796 27.0733,214.9906 C31.5773,195.1786 19.3673,169.5856 27.9903,151.7106 C36.7573,133.5406 64.4683,127.1816 76.9303,111.5736 C89.4533,95.8906 89.5333,67.4776 105.2163,54.9546 C120.8233,42.4926 148.5223,48.6936 166.6923,39.9266 C184.5673,31.3036 196.9323,5.6966 216.7433,1.1946 C235.9323,-3.1674 258.1363,14.4396 278.6433,14.4396 C299.1523,14.4396 321.3563,-3.1674 340.5443,1.1946 C360.3573,5.6966 372.7223,31.3036 390.5973,39.9276 C408.7673,48.6936 436.4663,42.4926 452.0733,54.9546 C467.7563,67.4786 467.8363,95.8916 480.3583,111.5746 C492.8213,127.1816 520.5323,133.5416 529.2983,151.7116 C537.9213,169.5866 525.7113,195.1786 530.2153,214.9906 C534.5763,234.1796 556.7883,251.9146 556.7883,272.4236 Z" id="Stroke-1"class="animated rotator pulse reverse"></path>
            <path d="M491.1295,272.4167 C491.1295,294.3957 467.9835,312.4467 461.5435,332.2787 C454.8695,352.8327 462.7545,380.9977 450.3045,398.1057 C437.7285,415.3857 408.4205,416.5297 391.1415,429.1057 C374.0325,441.5567 363.9565,469.1457 343.4025,475.8197 C323.5705,482.2597 299.2925,466.0717 277.3155,466.0717 C255.3365,466.0717 231.0595,482.2597 211.2275,475.8197 C190.6735,469.1457 180.5965,441.5567 163.4885,429.1057 C146.2085,416.5297 116.9005,415.3857 104.3255,398.1057 C91.8755,380.9977 99.7605,352.8327 93.0865,332.2787 C86.6475,312.4477 63.4995,294.3957 63.4995,272.4167 C63.4995,250.4387 86.6475,232.3867 93.0865,212.5557 C99.7605,192.0017 91.8745,163.8357 104.3255,146.7277 C116.9005,129.4477 146.2085,128.3037 163.4885,115.7287 C180.5965,103.2777 190.6735,75.6877 211.2275,69.0147 C231.0585,62.5747 255.3365,78.7627 277.3155,78.7627 C299.2925,78.7627 323.5705,62.5747 343.4025,69.0147 C363.9565,75.6877 374.0325,103.2767 391.1415,115.7287 C408.4205,128.3037 437.7285,129.4477 450.3045,146.7287 C462.7545,163.8357 454.8695,192.0017 461.5435,212.5557 C467.9825,232.3867 491.1295,250.4387 491.1295,272.4167 Z" id="Stroke-3"class="animated rotator pulse"></path>

            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #ffffff; animation-delay: 0.0s' class=" oh-oh-oh reverse"></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #ffffff; animation-delay: 0.0s' class=" oh-oh-oh "></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #d41367; animation-delay: 0.125s' class=" oh-oh-oh reverse"></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #d41367; animation-delay: 0.125s' class=" oh-oh-oh "></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #ffffff; animation-delay: 0.3s' class=" oh-oh-oh reverse"></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #ffffff; animation-delay: 0.3s' class=" oh-oh-oh "></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #d41367; animation-delay: 0.525s' class=" oh-oh-oh reverse"></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #d41367; animation-delay: 0.525s' class=" oh-oh-oh "></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #ffffff; animation-delay: 0.8s' class=" oh-oh-oh reverse"></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #ffffff; animation-delay: 0.8s' class=" oh-oh-oh "></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #d41367; animation-delay: 1.125s' class=" oh-oh-oh reverse"></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #d41367; animation-delay: 1.125s' class=" oh-oh-oh "></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #ffffff; animation-delay: 1.5s' class=" oh-oh-oh reverse"></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #ffffff; animation-delay: 1.5s' class=" oh-oh-oh "></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #d41367; animation-delay: 1.925s' class=" oh-oh-oh reverse"></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #d41367; animation-delay: 1.925s' class=" oh-oh-oh "></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #ffffff; animation-delay: 2.4s' class=" oh-oh-oh reverse"></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #ffffff; animation-delay: 2.4s' class=" oh-oh-oh "></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #d41367; animation-delay: 2.925s' class=" oh-oh-oh reverse"></path>
            <path d="M431.403,272.7192 C431.403,288.2402 409.279,300.0732 404.731,314.0772 C400.018,328.5922 410.799,351.1392 402.007,363.2202 C393.126,375.4222 368.292,372.0932 356.09,380.9742 C344.008,389.7662 339.55,414.4612 325.036,419.1732 C311.032,423.7212 292.972,406.5112 277.452,406.5112 C261.931,406.5112 243.873,423.7212 229.868,419.1742 C215.353,414.4612 210.895,389.7662 198.813,380.9742 C186.611,372.0932 161.778,375.4222 152.897,363.2202 C144.105,351.1392 154.884,328.5922 150.173,314.0772 C145.625,300.0732 123.5,288.2402 123.5,272.7192 C123.5,257.1992 145.625,245.3652 150.172,231.3622 C154.884,216.8472 144.105,194.3002 152.897,182.2182 C161.778,170.0162 186.611,173.3462 198.813,164.4652 C210.895,155.6732 215.353,130.9782 229.867,126.2652 C243.872,121.7182 261.931,138.9282 277.452,138.9282 C292.972,138.9282 311.031,121.7182 325.036,126.2652 C339.55,130.9782 344.008,155.6732 356.09,164.4652 C368.292,173.3462 393.126,170.0162 402.007,182.2182 C410.799,194.3002 400.018,216.8472 404.731,231.3612 C409.279,245.3652 431.403,257.1982 431.403,272.7192 Z" style='color: #d41367; animation-delay: 2.925s' class=" oh-oh-oh "></path>
        </g>
    </g>
</svg>`;
