export default {
    currentPage: 0,
    lastPage: 0,
    direction: 'down',
    isLoading: true,
    isGated: true,
    isChallenge: false,
    isLockedState: true,
    nickname: '',
    showWave: false,
    challengePoints: 0,
    challengePages: [3, 5, 7, 10],
};
