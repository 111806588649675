import { html, render } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import store from '@/modules/PubSub/store/index.js';
import { promptContent } from '@/components/ChallengePrompt/prompt-content';

const ChallengePrompt = (() => {
    class ChallengePrompt {
        constructor() {
            this.node = document.querySelector('.challenge-prompt');
            this.node.utils = new DomUtils(this.node);
            this.store = store;
            this.prompt = null;
            this.reveal = document.querySelectorAll('.fp-section.active .reveal');
            this.stage = null;
            this.siteLogo = document.querySelector('.site-logo .oh-logo.animated');
            this.isChallenge = false;
            this.hasRevealed = false;
            this.intialStatus;
            this.status = null;
        }

        onEuphoria() {
            this.siteLogo.classList.add('pulse-now');

            setTimeout(() => {
                this.siteLogo.classList.remove('pulse-now');
            }, 600);
        }

        onCompletion() {
            //stop everything and show the feel-good message.
            this.hasRevealed = false;

            this.reveal.classList.add('fade-out');

            setTimeout(() => {
                this.updateContent('just_right');
            }, 300);

            this.siteLogo.classList.add('jello');

            setTimeout(() => {
                this.siteLogo.classList.remove('jello');
            }, 1000);
        }

        getContent(key) {
            let dialouge = this;

            if (key == 'intro') {
                dialouge = 'intro';
            }

            if (window.tooSlow && !window.justStarted) {
                dialouge = 'slow';
            }

            if (window.tooFast) {
                dialouge = 'fast';
            }

            if (key == 'just_right') {
                dialouge = 'just_right';
            }

            return promptContent[this.stage][dialouge];
        }

        updateContent(key) {
            if (this.hasRevealed) return;

            let content = this.getContent(key);

            let reveal = document.querySelectorAll('.fp-section.active .reveal');
            this.reveal = reveal[0];

            if (reveal.length) {
                this.reveal.classList.add('fade-out');

                setTimeout(() => {
                    render(content, this.prompt);
                }, 300);
            }

            let delay = 600;

            if (key == 'intro') {
                delay = 250;
            }

            setTimeout(() => {
                this.hasRevealed = true;

                if (this.reveal && typeof this.reveal !== undefined) {
                    this.reveal.classList.remove('fade-out');
                    this.reveal.classList.remove('pulse-now');
                    this.reveal.classList.add('fade-in');

                    setTimeout(() => {
                        this.reveal.classList.add('pulse-now');
                    }, 250);

                    setTimeout(() => {
                        //this.reveal.classList.add('fade-out');
                        this.hasRevealed = false;
                    }, 4500);
                }
            }, delay);
        }

        onFullpageLeave() {
            setTimeout(() => {
                if (document.querySelectorAll('.fp-section.active .challenge-prompt').length) {
                    this.isChallenge = true;
                    this.status = window.challengeStatus;
                    this.intialStatus = this.status;
                    this.prompt = document.querySelector('.fp-section.active [data-js="prompt-text"]');

                    this.stage = document.querySelector('.fp-section.active .challenge-prompt').getAttribute('data-index');

                    this.updateContent('intro');
                }
            }, 250);
        }

        onStatusUpdate() {
            this.status = window.challengeStatus;
            this.updateContent();
        }

        addEventListeners() {
            window.addEventListener('fullpageOnLeave', this.onFullpageLeave.bind(this));
            window.addEventListener('challengeStatusUpdate', this.onStatusUpdate.bind(this));
            window.addEventListener('positiveReinforcement', this.onEuphoria.bind(this));
            window.addEventListener('challengeCompletion', this.onCompletion.bind(this));
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }

            this.addEventListeners();
        }
    }

    return {
        init() {
            return new ChallengePrompt().init();
        },
    };
})();

export default Object.create(ChallengePrompt);

export const challengePrompt = (index, isMobile) => html`
    <div class="challenge-prompt" data-index="${index}">
        <div class="challenge-prompt__container animated reveal pulse">
            ${isMobile
                ? html`
                      <svg
                          width="89px"
                          height="194px"
                          viewBox="0 0 89 194"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                          <!-- Generator: Sketch 52.6 (67491) - http://www.bohemiancoding.com/sketch -->
                          <title>mobile_scrollIndicator</title>
                          <desc>Created with Sketch.</desc>
                          <g
                              id="Page-1"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                          >
                              <g id="mobile_scrollIndicator" transform="translate(2.000000, 2.000000)" stroke="#000000" stroke-width="5">
                                  <path d="M66,169.7 C63.4,172.3 47.1,188.6 47.1,188.6 L27.9,169.4" id="Stroke-3"></path>
                                  <polyline id="Stroke-5" points="27.9 19.4 46.8 0.5 66 19.7"></polyline>
                                  <polyline id="Path" points="36.3 66 74.2 66.9 84.2 104.1 78.5 121.1 80.8 129.7"></polyline>
                                  <path d="M48.9,68.8 L52.8,83.2" id="Path"></path>
                                  <path d="M61.9,69.2 L65.8,83.5" id="Path"></path>
                                  <path
                                      d="M39.9,82.9 L30.7,48.4 C29.6,44.4 25.5,42 21.5,43.1 C17.5,44.2 15.1,48.3 16.2,52.3 L26.7,91.6 C27.7,95.2 25.5,98.9 21.9,99.9 L21.5,100 L14.6,95.3 C10.8,92.7 5.7,93.7 3.2,97.5 L2.3,98.8 C-0.3,102.6 0.7,107.7 4.5,110.2 L36.9,132.3 L39.2,140.8"
                                      id="Path"
                                  ></path>
                              </g>
                          </g>
                      </svg>
                  `
                : html`
                      <svg
                          width="65px"
                          height="194px"
                          viewBox="0 0 65 194"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                          <!-- Generator: Sketch 52.6 (67491) - http://www.bohemiancoding.com/sketch -->
                          <g
                              id="Page-1"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                          >
                              <g id="Artboard" stroke="currentColor" stroke-width="5">
                                  <g id="Group-9" transform="translate(2.000000, 2.000000)">
                                      <path
                                          d="M30.2839,150.1048 C13.8349,150.1048 0.4999,136.7698 0.4999,120.3208 L0.4999,67.5058 C0.4999,51.0578 13.8349,37.7228 30.2839,37.7228 C46.7329,37.7228 60.0679,51.0578 60.0679,67.5058 L60.0679,120.3208 C60.0679,136.7698 46.7329,150.1048 30.2839,150.1048 Z"
                                          id="Stroke-1"
                                      ></path>
                                      <path
                                          d="M49.3532,169.7474 C46.7322,172.3664 30.4602,188.6394 30.4602,188.6394 L11.2142,169.3934"
                                          id="Stroke-3"
                                          data-js="arrow-down"
                                      ></path>
                                      <path
                                          d="M11.2145,19.3924 L30.1085,0.4994 C30.1085,0.4994 49.2645,19.6574 49.3535,19.7464"
                                          id="Stroke-5"
                                          data-js="arrow-up"
                                      ></path>
                                      <path d="M30.2839,55.075 L30.2839,70.429" id="Stroke-7"></path>
                                  </g>
                              </g>
                          </g>
                      </svg>
                  `}

            <div data-js="prompt-text" class="challenge-prompt__text">Ready when you are. Start scrolling.</div>
        </div>
    </div>
`;
