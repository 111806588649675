import { html, render } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import store from '@/modules/PubSub/store/index.js';
import { infoSectionContentReveal } from './InfoSection-content';
import throttle from 'lodash.throttle';
import { bottomIsInViewport } from '../../helpers/utilities';

const InfoSectionMobile = (() => {
    class InfoSectionMobile {
        constructor() {
            this.node = document.querySelector('.info-section__scroller');
            this.node.utils = new DomUtils(this.node);
            this.msg = this.node.querySelector('.ready-screen__msg');
            this.nav = document.querySelector('.site-nav');
            this.logo = document.querySelector('.site-logo');
            this.revealPieces = ['.info-section__text', '.info-section__afterthought', '.reveal', '.info-section__afterthought-dropzone'];
            this.scrollMarker = null;
            this.store = store;
            this.parallaxWrapper = this.node;
            this.isFinished = false;
            this.hasRevealed = false;
            this.hasRevealedIngredients = false;
            this.navIsHidden = false;
            this.handleErrantScroll = null;
            this.originalNavTop = this.nav.offsetTop;
            this.standardDelay = 2500;
            this.iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        }

        revealContent() {
            this.revealPieces.forEach((selector, index) => {
                let elements = document.querySelectorAll('.fp-section.active ' + selector);

                elements.forEach((element, index) => {
                    if (bottomIsInViewport(element, 100)) {
                        setTimeout(() => {
                            element.classList.add('fade');
                            element.classList.add('fade-in');
                        }, 300);
                    }
                });
            });
        }

        setScrollMarker() {
            this.node = document.querySelector('.fp-section.active .info-section__scroller');
            this.scrollMarker = document.querySelector('.fp-section.active [data-js="check-finished"]');
        }

        modContent() {
            if (document.querySelectorAll('.fp-section.active .info-section-2').length) {
                let header = document.querySelector('.fp-section.active .info-section-2 h1');
                let textDiv = document.querySelector('.fp-section.active .info-section-2 .info-section__text');
                let afterthoughtDiv = document.querySelector('.fp-section.active .info-section-2 .info-section__afterthought');

                //steal the header and put it into normal document flow
                textDiv.prepend(header);

                /*insert the third piece without replacing any content (because the 
                content is a tagged template literal that must be rendered)*/
                let dropzone = `
                <div class="info-section__afterthought-dropzone fade"></div>
                `;
                afterthoughtDiv.insertAdjacentHTML('beforeend', dropzone);

                let dropzoneDiv = document.querySelector('.info-section__afterthought-dropzone');

                render(infoSectionContentReveal, dropzoneDiv);
            }
        }

        navFlyAway() {
            if (this.node.scrollTop > 200) return;

            let navTop = this.nav.offsetTop;
            let logoTop = this.logo.offsetTop;
            let adjustment = navTop - this.node.scrollTop;
            this.nav.style.top = adjustment / 2 + 'px';
            this.logo.style.top = adjustment / 2 + 'px';

            if (this.node.scrollTop < 15) {
                this.bringNavBack();
            }
        }

        bringNavBack() {
            this.nav.style.top = this.originalNavTop + 'px';
            this.logo.style.top = 0 + 'px';
        }

        backToTop(){
            if(this.node.scrollTop > 0){
                this.node.style.opacity = 0;
                this.node.scrollTop = 0;
                this.node.style.opacity = 1;
            }
        }

        checkFinished() {
            if (this.isFinished) return;

            if (bottomIsInViewport(this.scrollMarker, 0) || this.node.scrollTop >= this.node.scrollHeight - 800) {
                this.isFinished = true;
                this.node.removeEventListener('scroll', this.throttled);
                this.showScrollIndicator();
                this.completeChallenge();
            }

            if (this.node.scrollTop > 5) {
                this.hasBegunScroll = true;
            }

            if (this.hasBegunScroll && this.node.scrollTop < 10) {
                this.canLeaveFromTop = true;
                window.dispatchEvent(new Event('allowLeaveFromTop'));
            }

            if (this.hasBegunScroll && this.node.scrollTop > 50) {
                if(!this.canLeaveFromTop) return;
                window.dispatchEvent(new Event('disallowLeaveFromTop'));
                this.canLeaveFromTop = false;
            }
        }

        showScrollIndicator() {
            setTimeout(() => {
                window.dispatchEvent(new Event('scrollIndicatorShow'));
            }, 500);
        }

        completeChallenge() {
            store.dispatch('setChallengePoints', this.store.state.currentPage - 2);
            window.dispatchEvent(new Event('triggerDebouncedAdvance'));
        }

        onFullpageLeave() {
            setTimeout(() => {
                if (document.querySelectorAll('.fp-section.active .info-section').length) {
                    console.log('start Reading Mode');
                    this.node = document.querySelector('.fp-section.active .info-section__scroller');
                    this.hasBegunScroll = false;
                    this.isFinished = false;
                    this.hasRevealed = false;
                    this.navIsHidden = false;
                    this.backToTop();
                    this.setScrollMarker();
                    this.modContent();
                    this.setScrollEventListeners();

                    if (this.iOS) {
                        this.iOSscrollFix();
                    }
                } else {
                    this.bringNavBack();

                    if (this.iOS) {
                        this.removeiOSscrollFix();
                    }
                }
            }, 200);
        }

        fauxScroll(e){
            /* to handle errant scroll going to the window instead of the scrolling div--
            the idea would be to get the distance that the user is supposed 
            to be scrolling and apply that to this.node */
        }

        iOSscrollFix() {
            var touchstart = new Event('touchstart');
            var touchend = new Event('touchend');
            
            const handleErrantScroll = (e) => {
            
                this.node.dispatchEvent(touchstart);
                this.node.dispatchEvent(touchend);

                window.fullpage_api.unHijack();

                //this.fauxScroll(e);
            };

            this.handleErrantScroll = throttle(handleErrantScroll, 250);
          
            window.addEventListener('scroll', this.handleErrantScroll);
        }

        removeiOSscrollFix() {
            window.removeEventListener('scroll', this.handleErrantScroll);
        }

        addEventListeners() {
            window.addEventListener('fullpageOnLeave', this.onFullpageLeave.bind(this));
            this.setScrollEventListeners();
        }

        setScrollEventListeners() {
            this.throttled = throttle(this.checkFinished.bind(this), 250);
            this.throttledReveal = throttle(this.revealContent.bind(this), 250);
            this.throttleFlyAway = throttle(this.navFlyAway.bind(this), 50);

            this.node.addEventListener('scroll', this.throttled);
            this.node.addEventListener('scroll', this.throttledReveal);
            this.node.addEventListener('scroll', this.navFlyAway.bind(this));
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }
            this.addEventListeners();
        }
    }

    return {
        init() {
            return new InfoSectionMobile().init();
        },
    };
})();

export default Object.create(InfoSectionMobile);
