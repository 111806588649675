import { html } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import { isMobile, isMobileSize } from '@/helpers/utilities';

const OhVideo = (() => {
    class OhVideo {
        constructor() {
            this.node = document.querySelector('.fullscreen-video-background');
            this.OHsvg = this.iframe = null;
            this.logo = document.querySelector('.site-logo');
            this.nav = document.querySelector('.site-nav');
            this.YTPlayer = null;
            this.gif = document.querySelector('[data-js="orgasm-gif"]');
            this.node.utils = new DomUtils(this.node);
        }

        OHanimation() {
            let ohO = document.querySelector('.OH-O');
            let ohH = document.querySelector('.OH-H');
            let ohContainer = document.querySelector('.climax-OH');
            let bigOH = document.querySelector('.big-OH');
            let productImg = document.querySelector('.conversion-form__container .product-image');
            let conversionForm = document.querySelector('.conversion-form-wrapper');
            let wave = document.querySelector('.the-wave');
            let siteLogo = document.querySelector('.site-logo .site-logo__container');

            //if the wave is still showing for any reason, hide it
            wave.style.display = 'none';
            siteLogo.classList.add('opacity-override');

            //zoom the OH to be huge
            ohContainer.classList.remove('opacity-override');
            ohContainer.classList.add('gigantic');

            //before animation prep the conversion screen
            bigOH.classList.add('opacity-override');

            //logo covers full screen then
            setTimeout(() => {
                let activeSection = window.fullpage_api.getActiveSection();
                window.fullpage_api.moveTo(activeSection.index + 2);

                ohContainer.classList.remove('tiny');
                ohContainer.classList.remove('gigantic');
                ohContainer.classList.remove('centered');

                /*———— -Begin the Animation triangle hell- ————*/

                //logo in place on the screen then
                setTimeout(() => {
                    ohH.classList.add('js-is-active');

                    setTimeout(() => {
                        //give the logo a jello shake
                        ohContainer.querySelector('svg').classList.add('jello');

                        setTimeout(() => {
                            bigOH.classList.remove('opacity-override');

                            setTimeout(() => {
                                //fade out the climax OH
                                ohContainer.classList.add('opacity-override');

                                setTimeout(() => {
                                    //show the site logo
                                    siteLogo.classList.remove('opacity-override');

                                    setTimeout(() => {
                                        //fade in the conversion content
                                        productImg.style.opacity = 1;

                                        [this.logo, this.nav].forEach((element) => {
                                            element.classList.remove('fade-out');
                                            element.classList.add('fade-in');
                                        });

                                        //on mobile there ain't no product image
                                        if (isMobileSize) {
                                            conversionForm.style.opacity = 1;
                                        }

                                        //bring in the form
                                        setTimeout(() => {
                                            conversionForm.style.opacity = 1;
                                        }, 600);
                                        //
                                    }, 600);
                                    //
                                }, 300);
                                //
                            }, 300);
                            //
                        }, 1500);
                        //
                    }, 800);
                    //
                }, 500);
                //
            }, 1200);
        }

        triggerVideo() {
            
            if(isMobile || isMobileSize){
                let imgSrc = this.gif.getAttribute('data-src');
                
                this.gif.setAttribute('src', imgSrc);
                
                this.gif.addEventListener('load' , () => {
                    console.log('gifLoaded');
                    window.dispatchEvent(new Event('PlaybackStarted'));
                });
                
            } else{
                this.YTPlayer.mute();
                this.YTPlayer.playVideo();    
            }
        }

        onVideoPlayback() {
            setTimeout(() => {
                this.node.classList.add('js-is-active');

                //start the OH animation
                setTimeout(() => {
                    this.OHanimation();
                }, 2250);

                setTimeout(() => {
                    this.node.classList.remove('js-is-active');
                }, 3500);
            }, 100);
        }

        initYTAPI() {
            // 2. This code loads the Youtube IFrame Player API code asynchronously.
            var tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }

        initVideo() {
            var _youtube_id = document.getElementById('_youtube-iframe');
            this.YTPlayer = new YT.Player('_youtube-iframe', {
                videoId: _youtube_id.dataset.youtubeurl,
                playerVars: {
                    // https://developers.google.com/youtube/player_parameters?playerVersion=HTML5
                    cc_load_policy: 0, // closed caption
                    controls: 0,
                    disablekb: 0, //disable keyboard
                    iv_load_policy: 3, // annotations
                    playsinline: 1, // play inline on iOS
                    rel: 0, // related videos
                    showinfo: 0, // title
                    modestbranding: 1, // youtube logo
                },
                events: {
                    //onReady: onYoutubePlayerReady,
                    onStateChange: function(event) {
                        if (event.data == YT.PlayerState.PLAYING) {
                            window.dispatchEvent(new Event('PlaybackStarted'));
                        }
                    },
                },
            });

            this.iframe = this.node.querySelector('#_youtube-iframe');
        }

        addEventListeners() {
            window.addEventListener('OHVideoReady', this.initVideo.bind(this));
            window.addEventListener('triggerVideoStart', this.triggerVideo.bind(this));
            window.addEventListener('PlaybackStarted', this.onVideoPlayback.bind(this));
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }
            this.addEventListeners();
            this.initYTAPI();
        }
    }

    return {
        init() {
            return new OhVideo().init();
        },
    };
})();

window.onYouTubeIframeAPIReady = () => {
    window.dispatchEvent(new Event('OHVideoReady'));
};

export default Object.create(OhVideo);

//prettier-ignore
export const ohVideo = html`
    
    <div class="fullscreen-video-background">
        <div id="_buffering-background"></div>
        <div class="fullscreen-gif-background">
            <img data-js="orgasm-gif" data-src="dist/images/orgasm.gif" src="" />
        </div>

        <div id="_youtube-iframe-wrapper">
            <div id="_youtube-iframe" data-youtubeurl="XHTArbZU4BQ"></div>
        </div>
    </div>
`;
