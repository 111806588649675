import { html } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import store from '@/modules/PubSub/store/index.js';
import { isMobileSize } from '@/helpers/utilities';

const Intro = (() => {
    class Intro {
        constructor() {
            this.node = document.querySelector('.intro');
            this.node.utils = new DomUtils(this.node);
            this.oh = document.querySelector('.site-logo__container');
            this.product = document.querySelector('.intro__product-image');
            this.ageGate = document.querySelector('.intro__age-gate');
            this.$trigger = $('form', this.node.utils);
            this.textContent = document.querySelector('.intro__text-header');
            this.store = store;
        }

        loadingAnimation() {
            setTimeout(() => {
                this.node.classList.remove('loading');
                this.oh.classList.remove('loading');
                this.store.dispatch('setLoadingState', false);

                setTimeout(() => {
                    this.product.classList.add('wiggle');
                }, 750);
            }, 500);
        }

        showWarningMessage(message) {
            const warningMsg = document.querySelector('.warning-msg');
            warningMsg.textContent = message;
            warningMsg.style.display = 'block';
            setTimeout(() => {
                warningMsg.style.opacity = 1;
            }, 50);
        }

        revealAgeGate() {
            setTimeout(() => {
                this.ageGate.classList.add('js-is-active');
            }, 1750);
        }

        calculateAge(dob) {
            const birthDate = new Date(dob);
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }

        addEventListeners() {
            const form = document.getElementById('age-verification-form');
            form.addEventListener('submit', (event) => {
                event.preventDefault();
                const dob = document.getElementById('age-gate-input').value;
                if (!dob) {
                    this.showWarningMessage('Please enter your date of birth.');
                    return;
                }
                if (this.calculateAge(dob) >= 21) {
                    this.ageGate.classList.remove('js-is-active');
                    this.loadingAnimation();
                    this.store.dispatch('setAgeGate', false);
                } else {
                    this.showWarningMessage('You must be at least 21 years old to visit this site.');
                }
            });
        }

        mobileContentMod() {
            if (isMobileSize) {
                this.textContent.prepend(this.product);
            }
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }

            this.mobileContentMod();
            this.revealAgeGate();
            this.addEventListeners();
        }
    }

    return {
        init() {
            return new Intro().init();
        },
    };
})();

export default Object.create(Intro);

//prettier-ignore
export const intro = html`
    <div class="intro loading">
        
        <div class="intro__container">
            <div class="intro__big-hello">
                <h1 class="huge">hello.</h1>
            </div>
            
            <div class="intro__text">
                <div class="intro__text-header">
                    <h1>This is OH.</h1>
                </div>
                <hr class="succinct" />
                
                <p>What started out as THC-infused Intimacy Oil has swelled into a <strong style=""class="text-magenta">multiple OH phenomenon</strong>. One after another, the products kept coming and so did the fans. Oil, chews, vapes, and pre-rolls. They’re all here. Ready and willing. (Don’t keep them waiting.)</p>
            
                <p><strong style=""class="text-magenta">Follow the directions as you go. This site has needs that only your scrolling fingers can satisfy.</strong></p>
            </div>
            
            <img class="intro__product-image" src="/dist/images/Oh-Product-Line-Up_3-products.png" alt="A bottle of Curio Oh Lubricant" /> 

        </div>

        <div class="intro__age-gate animated">
            
            <form id="age-verification-form">
                <label class="label large" for="age-gate-input" data-js="age-gate-input">You must be 21 years old to visit this site. Please verify your age</label>
                <div class="age-gate-form__container">
                <input type="date" name="dateofbirth" id="age-gate-input" class="age-field animated">
                <input class="cta-btn" type="submit" value="Submit"/>
                </div>
                <p class="small text-magenta warning-msg"></p>
            </form>
        </div>
        
    </div>
`;
