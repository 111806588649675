import 'nodelist-foreach-polyfill';
import Page from '@/page.js';
import Screenfull from '@/modules/Screenfull';
import WaveGame from '@/modules/WaveGame';
import SVGbundle from '@/modules/SVGbundle';
import MasterController from './modules/MasterController';
import OH from './components/OH';
import Intro from './components/Intro';
import ScrollIndicator from './components/ScrollIndicator';
import NicknameForm from './components/NicknameForm';
import ReadyScreen from './components/ReadyScreen';
import TheWave from './components/TheWave';
import InfoSection from './components/infoSection/InfoSection';
import ConversionForm from './components/ConversionForm';
import ChallengePrompt from './components/ChallengePrompt/ChallengePrompt';
import ThankYou from './components/ThankYou';
import Orgasm from './components/orgasm/orgasm';
import OhVideo from './components/orgasm/o-video';
import CursorFeedback from './components/ChallengePrompt/CursorFeedback';
import { VHfix } from './libraries/vh-fix';
import { isMobile, isMobileSize } from './helpers/utilities';
import ParallaxMousemove from './modules/ParallaxMousemove';
import TheNav from './components/TheNav';
import InfoSectionMobile from './components/infoSection/InfoSectionMobile';
import BrowserTabs from './modules/BrowserTabs';

document.addEventListener('DOMContentLoaded', function () {
    /*a bugfix that forces the browser to go back
    to the top on reload */
    if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
    }

    MasterController.init();

    /*———— -Render components into the root div- ————*/
    Page.init({
        selector: '.lit-html-root',
    });

    Screenfull.init({
        selector: '.screenfull__container',
    });

    ParallaxMousemove.init();

    SVGbundle.init({
        selector: '[data-js="svg-bundle"]',
    });

    WaveGame.init({
        selector: '.the-wave',
    });

    BrowserTabs.init();


    /*———— -Init Component Specific Logic- ————*/
    ChallengePrompt.init();
    ConversionForm.init();
    CursorFeedback.init();
    Intro.init();
    OH.init();
    Orgasm.init();
    OhVideo.init();
    NicknameForm.init();
    ReadyScreen.init();
    ScrollIndicator.init();
    ThankYou.init();
    TheNav.init();
    TheWave.init();

    /*———— -Device specific scripts- ————*/
    if (!isMobile && !isMobileSize) {
        InfoSection.init();
    } else {
        InfoSectionMobile.init();
    }

    //iOS Devices
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        VHfix();

        //True lock scroll on iOS
        var freezeVp = function (e) {
            e.preventDefault();
        };

        document.body.addEventListener("touchmove", freezeVp, false);
    }

    /*———— -Multi-componentized implementations- ————*/
    /*
    const menu_accordions = document.querySelectorAll('.menu-item-has-children');

    menu_accordions.forEach((element) => {
        menu_accordion.init({
            selector: element,
        });
    });
    */

    /*———— -iOS viewport fix for mobile- ————*/
});

window.addEventListener('load', function () {
    //onload functions here
});
