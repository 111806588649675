import { html, render } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import store from '@/modules/PubSub/store/index.js';

const CursorFeedback = (() => {
    class CursorFeedback {
        constructor() {
            this.node = document.querySelector('.cursor-feedback p');
            this.parentNode = document.querySelector('.cursor-feedback');
            this.node.utils = new DomUtils(this.node);
            this.store = store;
            this.hasRevealed = false;
            this.nicknameIsSet = false;
            this.yes = html`
                Yes
            `;
            this.wordBank = [this.yes, 'Yeeeeah', 'Oh yes.', 'That’s great.'];
        }

        getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        }

        revealMessage(event, customMsg) {
            let whichMsg = this.getRandomInt(this.wordBank.length);
            let word = customMsg ? customMsg : this.wordBank[whichMsg];
            let size = window.clickCount;
            let msg = html`
                <p class="animated size-${size}">${word}</p>
            `;

            //render
            render(msg, this.parentNode);

            //reselect the node
            this.node = document.querySelector('.cursor-feedback p');

            let msgClone = this.node.cloneNode(true);
            this.node.insertAdjacentElement('afterend', msgClone);

            if (customMsg) {
                msgClone.classList.add('fade-up-long');
            } else {
                msgClone.classList.add('fade-up');
            }

            msgClone.classList.add('cloned');

            setTimeout(() => {
                msgClone.remove();
            }, 900);
        }

        onCalloutName() {
            if (!this.nicknameIsSet) return;

            let doubleConsonantPattern = /([b-df-hj-np-tv-z])\1$/i;
            let firstVowelPattern = /([aeiou])/i;
            let nickname = this.store.state.nickname;

            if (doubleConsonantPattern.test(nickname)) {
                let consonantToRepeat = nickname[nickname.length - 1];

                for (let i = 0; i < 4; i++) {
                    nickname += consonantToRepeat;
                }
            } else {
                let pieces = nickname.split(firstVowelPattern);
                let firstVowel = pieces[1].toLowerCase();

                for (let i = 0; i < 4; i++) {
                    pieces[1] += firstVowel;
                }

                nickname = pieces.join('');
            }

            let vocalization = 'Oh, ' + nickname + '!';
            this.revealMessage('', vocalization);
        }

        onUpdateNickname() {
            if (this.store.state.nickname && this.store.state.nickname !== 'blank') {
                this.yes = html`
                    Yes, <span data-js="nickname-feedback">${this.store.state.nickname}</span>
                `;
                this.wordBank[0] = this.yes;
                this.nicknameIsSet = true;

            }
        }

        addEventListeners() {
            window.addEventListener('updateNickname', this.onUpdateNickname.bind(this));
            window.addEventListener('positiveReinforcement', this.revealMessage.bind(this));
            window.addEventListener('calloutName', this.onCalloutName.bind(this));
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }

            window.addEventListener('load', () => {
                this.addEventListeners();
            });
        }
    }

    return {
        init() {
            return new CursorFeedback().init();
        },
    };
})();

export default Object.create(CursorFeedback);

export const cursorFeedback = html`
    <div class="cursor-feedback animated">
        <p class="animated">Yes!</p>
    </div>
`;
