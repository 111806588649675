import { html } from 'lit-html';
import DomUtils from '@/helpers/utilities';

const TheNav = (() => {
    class TheNav {
        constructor() {
            this.node = document.querySelector('.site-nav');
            this.node.utils = new DomUtils(this.node);
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        onFullpageLeave() {}

        addEventListeners() {
            window.addEventListener('fullpageOnLeave', this.onFullpageLeave.bind(this));
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }
            this.addEventListeners();
        }
    }

    return {
        init() {
            return new TheNav().init();
        },
    };
})();

export default Object.create(TheNav);

//prettier-ignore
export const theNav = html`
    <div class="site-nav js-is-active">
        <a target="_blank" href="https://curiowellness.com/where-to-buy/">find</a>
        <a target="_blank" href="https://curiowellness.com/product/oh-intimacy-oil/">learn</a>
    </div>
`;
