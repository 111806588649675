import '@/libraries/path-data-polyfill';
import TweenLite from 'gsap/TweenLite';
import store from '@/modules/PubSub/store/index.js';
import throttle from 'lodash.throttle';
import { isMobile, isMobileSize } from '../helpers/utilities';

const WaveGame = (() => {
    class WaveGame {
        constructor() {
            this.store = store;
            this.currentPage = this.store.state.currentPage;
            this.challengePages = this.store.state.challengePages;
            this.container = document.querySelector('.the-wave__container');
            this.throttled = null;
            this.isChallenge = false;
            this.gameOn = false;
            this.gameStarterTimeout = null;
            this.history = [];
            this.rythym = 1;
            this.points = 0;
            this.level = 0;
            this.goal = 3;
            this.isFinished = false;
            this.$scope = null;
        }

        initPlugin() {
            /*———— -https://codepen.io/jaromvogel/pen/jWjWqN?editors=1000- ————*/

            let $scope = {};

            this.$scope = $scope;

            var path = document.querySelector('.sine-wave');

            //math constants
            var PI2 = Math.PI * 2;
            var HALFPI = Math.PI / 2;

            $scope.x = 0;
            $scope.offset = 1;

            $scope.fullLength = window.innerWidth;

            if ($scope.fullLength < 480) {
                $scope.fullLength = $scope.fullLength * 2;
            }

            $scope.median = $scope.fullLength / 2;

            $scope.reset = function() {
                $scope.frequency = 3;
                $scope.amplitude = 4;
                $scope.shadow = true;
                $scope.framerate = 60;
                $scope.increment = 8;
                $scope.rotation = 0;
                $scope.percent = null;
            };

            $scope.reset();

            var svg = document.querySelector('svg');

            const TweenChange = () => {
                if (!this.isChallenge) return;

                TweenLite.to($scope, 3, {
                    ease: Power4.easeOut,
                    amplitude: $scope.amplitude + 2.25,
                    frequency: $scope.frequency + 1.75,
                    increment: $scope.increment + 1,
                });
            };

            $scope.TweenChange = TweenChange;

            const ReverseTween = () => {
                let incrementAdjust = $scope.increment >= $scope.incrementThreshold ? $scope.increment - 0.75 : $scope.incrementThreshold;

                let amplitudeAdjust = $scope.amplitude >= $scope.threshold ? $scope.amplitude - 0.75 : $scope.threshold;

                let frequencyAdjust = $scope.frequency >= $scope.frequencyThreshold ? $scope.frequency - 0.75 : $scope.frequencyThreshold;

                if (window.tooSlow && $scope.amplitude > $scope.threshold) {
                    TweenLite.to($scope, 2, {
                        ease: Power1.easeOut,
                        amplitude: amplitudeAdjust,
                        frequency: frequencyAdjust,
                        increment: incrementAdjust,
                    });
                }
            };
            $scope.ReverseTween = ReverseTween;

            const TweenRevert = (amplitude, frequency, increment) => {
                this.container.style.opacity = 0;

                setTimeout(() => {
                    $scope.amplitude = amplitude;
                    $scope.frequency = frequency;
                    $scope.increment = increment;

                    setTimeout(() => {
                        this.container.style.opacity = 1;
                    }, 250);
                }, 700);
            };
            $scope.TweenRevert = TweenRevert;

            const setThreshold = () => {
                $scope.threshold = $scope.amplitude > 4 ? $scope.amplitude - 1 : 4;
                $scope.incrementThreshold = $scope.increment > 8 ? $scope.increment - 1 : 8;
                $scope.frequencyThreshold = $scope.frequency > 3 ? $scope.frequency - 1 : 3;
            };

            setThreshold();

            $scope.setThreshold = setThreshold;

            /*i know this is spaghetti code, this whole file needs some serious
            refactoring when time allows */

            $scope.pathFunction = function(x, increase) {
                var flipside = Math.abs((x % (this.fullLength / 2)) - this.fullLength / 2);
                var coefficient = increase ? x : flipside;

                var result = '';

                $scope.percent = x / $scope.fullLength;
                $scope.variable_frequency = $scope.frequency * $scope.percent;

                result = Math.sin($scope.variable_frequency * (PI2 - HALFPI) - $scope.offset) * coefficient * (0.01 * $scope.amplitude);

                return result;
            };

            $scope.createGraph = function(wave) {
                $scope.x = 0;
                var data = [
                    {
                        type: 'M',
                        values: [0, 325],
                    },
                ];
                while ($scope.x < $scope.fullLength) {
                    let increase = $scope.x < $scope.fullLength / 2 ? true : false;
                    let point = {
                        x: $scope.x,
                        y: 325 - $scope.pathFunction($scope.x, increase),
                    };
                    data.push({
                        type: 'L',
                        values: [point.x, point.y],
                    });
                    $scope.x += 1;
                }

                wave.setPathData(data);
            };

            $scope.createInitalGraph = function(wave) {
                $scope.x = 0;
                var data = [
                    {
                        type: 'M',
                        values: [0, 325],
                    },
                ];
                while ($scope.x < $scope.fullLength) {
                    let point = {
                        x: $scope.x,
                        y: 325 - $scope.pathFunction($scope.x),
                    };
                    data.push({
                        type: 'L',
                        values: [point.x, point.y],
                    });
                    $scope.x += 1;
                }
                wave.setPathData(data);
            };
            $scope.createInitalGraph(path);

            $scope.play = true;

            $scope.animate = function() {
                if ($scope.play === true) {
                    $scope.offset += $scope.increment / $scope.framerate;
                    $scope.createGraph(path);
                    setTimeout(function() {
                        requestAnimationFrame($scope.animate);
                    }, 1000 / $scope.framerate);
                }
            };

            requestAnimationFrame($scope.animate);
        }

        startGame() {
            if (this.gameOn) return;
            this.gameOn = true;
            this.isFinished = false;
            console.log('game started');

            console.log('this.level is ' + this.level );

            this.goal = 4;

            if (isMobile || isMobileSize) {
                this.goal = this.goal + this.level;
            } else {
                this.goal = this.goal + this.level - 2;
            }

            //no matter what, it has to be at least 4
            this.goal = (this.goal > 3) ? this.goal : 4;

            console.log(this.goal);

            //this makes sure we don't give the user negative/inactivity messaging too early on
            window.justStarted = true;

            setTimeout(() => {
                window.justStarted = false;
            }, 3000);

            let rythymCounter = setInterval(() => {
                if (!this.isChallenge) return;

                if (this.rythym == 1 && this.inactivityMonitor && !this.isFinished) {
                    window.tooSlow = true;
                    this.inactivityMonitor = true;

                    window.dispatchEvent(new Event('challengeStatusUpdate'));
                    this.$scope.ReverseTween();
                }

                if (this.rythym == 1) {
                    this.inactivityMonitor = true;
                }

                if (this.points == this.goal || this.isFinished) {
                    clearInterval(rythymCounter);
                }

                window.tooSlow = false;
                window.justRight = false;
                window.tooFast = false;
                this.rythym = 1;
            }, 3000); // check every 3000ms
        }

        saveProgress() {
            /* We save the progress so when the user scrolls back up the page,
            we can revert the wave to match */
            this.history[this.level] = {};

            this.history[this.level].amplitude = this.$scope.threshold;
            this.history[this.level].increment = this.$scope.incrementThreshold;
            this.history[this.level].frequency = this.$scope.frequencyThreshold;

            //console.log(this.history);
        }

        setLevel(direction) {
            if (direction == 'backward') {
                this.applyProgress();
                this.level = (this.level > 0) ? this.level-1 : 0;
            }

            if (direction == 'forward') {
                this.level++;
            }
        }

        applyProgress() {
            let history = this.history[this.level - 1];

            if (typeof history !== 'undefined' && history !== null) {
                this.$scope.TweenRevert(history.amplitude, history.frequency, history.increment);
            }
        }

        completeChallenge() {
            window.dispatchEvent(new Event('challengeCompletion'));
            this.isFinished = true;
            this.$scope.setThreshold();
            this.saveProgress();

            this.removeScrollEventListeners();

            const completionTimeout = setTimeout(() => {
                store.dispatch('setChallengePoints', this.store.state.currentPage - 2);
                window.dispatchEvent(new Event('triggerDebouncedAdvance'));
            }, 1500);

            this.completionTimeout = completionTimeout;
        }

        hardStop() {
            if (!this.isChallenge) return;

            clearTimeout(this.gameStarterTimeout);
            clearTimeout(this.completionTimeout);

            this.isFinished = true;
            this.removeScrollEventListeners();
            this.points = 0;
            this.gameOn = false;
        }

        bobWave() {
            let containerStyle = this.container.style;

            this.container.classList.add('feedback-animation');

            setTimeout(() => {
                this.container.classList.remove('feedback-animation');
            }, 400);
        }

        scoreScroll(e) {
            if (!this.gameOn) return;
            this.inactivityMonitor = false;
            window.dispatchEvent(new Event('positiveReinforcement'));
            //console.log('this.points is ' + this.points);

            this.rythym++;
            this.points++;

            if (this.points == this.goal) {
                //end the game
                this.$scope.TweenChange();

                this.completeChallenge();

                this.points = 0;
                this.gameOn = false;
            }

            if (this.points == 2) {
                this.$scope.TweenChange();
            }

            this.bobWave();
        }

        inChallengePages(arr, val) {
            return arr.some((arrVal) => val == arrVal);
        }

        onStateChange() {
            this.currentPage = this.store.state.currentPage;
        }

        onFullpageLeave() {
            let previousSection = window.fullpage_api.getActiveSection().index;
            let currentSection = null;
            this.hasLeft = false;

            setTimeout(() => {
                if (document.querySelectorAll('.fp-section.active .challenge-prompt').length) {
                    currentSection = window.fullpage_api.getActiveSection().index;
                    this.isChallenge = true;
                    this.hasLeft = false;

                    this.addScrollEventListeners();
                } else {
                    this.isChallenge = false;
                }
            }, 250);

            const gameStarterTimeout = setTimeout(() => {
                if (!this.isChallenge) return;
                if (this.hasLeft) return;

                let direction = previousSection < currentSection ? 'forward' : 'backward';

                this.setLevel(direction);
                this.startGame();
            }, 900);

            this.gameStarterTimeout = gameStarterTimeout;
        }

        throttle(func, wait, immediate) {
            var timeout;
            return function() {
                var context = this,
                    args = arguments;
                var later = function() {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        }

        removeScrollEventListeners() {
            document.removeEventListener('wheel', this.throttled, true);
            document.removeEventListener('touchmove', this.throttled, true);
            document.removeEventListener('wheel', this.throttledBobber, true);
            document.removeEventListener('touchmove', this.throttledBobber, true);
        }

        addScrollEventListeners() {
            this.throttled = throttle(this.scoreScroll.bind(this), 400, true);
            document.addEventListener('wheel', this.throttled, true);
            document.addEventListener('touchmove', this.throttled, true);
        }

        setEventBindings() {
            this.store.events.subscribe('stateChange', () => {
                this.onStateChange();
            });
            window.addEventListener('fullpageOnLeave', this.onFullpageLeave.bind(this));
            window.addEventListener('backwardsMove', this.hardStop.bind(this));
        }

        init() {
            this.initPlugin();
            this.setEventBindings();
            this.saveProgress();
        }
    }

    return {
        init() {
            return new WaveGame().init();
        },
    };
})();

export default Object.create(WaveGame);
