const SVGBundle = (() => {
    class SVGBundle {
        constructor(node) {
            this.node = document.querySelector(node);
            this.url = 'dist/svg-icons.bundleV0.1.0.svg';
            this.selector = node;
        }

        requestSVGBundle(url) {
            //This method comes from https://medium.com/@sam_potts/moving-from-icon-fonts-to-svg-sprites-262f78ac7741

            // Respect protocol for IE9
            // http://stackoverflow.com/a/8509981/1191319
            if (window.location.protocol === 'http:') {
                url = url.replace('https', 'http');
            }

            // Only load once
            if (document.querySelectorAll(this.selector).length === 1) {
                var xhr = new XMLHttpRequest(),
                    body = document.body;

                // Create container
                var container = this.node;

                // XHR for Chrome/Firefox/Opera/Safari
                if ('withCredentials' in xhr) {
                    xhr.open('GET', url, true);
                }
                // XDomainRequest for older IE
                else if (typeof XDomainRequest != 'undefined') {
                    xhr = new XDomainRequest();
                    xhr.open('GET', url);
                }
                // Not supported
                else {
                    return;
                }

                // Once loaded, inject to container and body
                xhr.onload = function() {
                    container.innerHTML = xhr.responseText;
                };

                // Timeout for IE9
                setTimeout(function() {
                    xhr.send();
                }, 0);
            }
        }

        init() {
            this.requestSVGBundle(this.url);
        }
    }

    return {
        init({ selector }) {
            return new SVGBundle(selector).init();
        },
    };
})();

export default Object.create(SVGBundle);
