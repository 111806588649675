import { html } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import store from '@/modules/PubSub/store/index.js';

const TheWave = (() => {
    class TheWave {
        constructor() {
            this.node = document.querySelector('.the-wave');
            this.node.utils = new DomUtils(this.node);
            this.store = store;
        }

        onFullpageLeave() {
            setTimeout(() => {
                if (this.store.state.currentPage == 3) {
                    this.show();
                }

                if (
                    this.store.state.currentPage > 10 ||
                    document.querySelectorAll('.fp-section.active .orgasm').length ||
                    document.querySelectorAll('.fp-section.active .ready-screen').length
                ) {
                    this.hide();
                }

                if (document.querySelectorAll('.fp-section.active .info-section').length) {
                    this.node.classList.add('reading-mode');
                } else {
                    this.node.classList.remove('reading-mode');
                }
            }, 250);
        }

        hide() {
            this.node.classList.remove('js-is-active');
            this.node.classList.add('js-is-hidden');
        }

        show() {
            this.node.classList.remove('js-is-hidden');
            this.node.classList.add('js-is-active');
        }

        addEventListeners() {
            window.addEventListener('fullpageOnLeave', this.onFullpageLeave.bind(this));
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }

            this.addEventListeners();
        }
    }

    return {
        init() {
            return new TheWave().init();
        },
    };
})();

export default Object.create(TheWave);

export const theWave = html`
    <div class="flex-center the-wave js-is-hidden">
        <div class="the-wave__container">
            <svg class="svg" width="1024" height="650">
                <path
                    class="sine-wave"
                    stroke="currentColor"
                    stroke-width="4"
                    stroke-linecap="round"
                    fill="none"
                    d="M0.5,0.5 L1440.5,0.5"
                />
            </svg>
        </div>
    </div>
`;
