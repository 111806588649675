import { html } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import store from '@/modules/PubSub/store/index.js';
import { isMobile, isMobileSize } from '@/helpers/utilities';

const NicknameForm = (() => {
    class NicknameForm {
        constructor() {
            this.node = document.querySelector('[data-js="nickname-input"]');
            this.submitBtn = document.querySelector('[data-js="nickname-submit"]');
            this.startTxt = document.querySelector('[data-js="start"]');
            this.node.utils = new DomUtils(this.node);
            this.store = store;
            this.hasEnterKeyBinding = false;
            this.iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        }

        onStateChange() {
            if (this.store.state.currentPage == 1 && !this.hasEnterKeyBinding) {
                this.setFocusOnInput();
                this.addEnterKeyListener();
                this.hasEnterKeyBinding = true;
            }
        }

        onFormSubmit(e) {
            e.preventDefault();
            e.stopPropagation();

            //ensure keyboard closes
            this.startTxt.focus();

            let nickname = this.node.value ? this.node.value : 'blank';
            store.dispatch('setNickname', nickname);
            window.dispatchEvent(new Event('updateNickname'));
        }

        onInputFocus(e) {
            /*stop bubbling up here, so that the mobile keyboard closes but 
            accidental swipes don't trigger scrolling*/
            e.stopPropagation();

            //android only
            if (isMobile || isMobileSize) {
                if (!this.iOS) {
                    this.header = document.querySelector('.nickname-form__header-group');
                    this.headerHeight = this.header.scrollHeight;

                    this.header.style.maxHeight = this.headerHeight + 'px';
                    this.header.style.maxHeight = '0px';
                }
            }
        }

        onInputBlur() {
            console.log('input blur');

            //android only
            if (isMobile || isMobileSize) {
                if (!this.iOS) {
                    this.header.style.maxHeight = this.headerHeight + 'px';
                }
            }
        }

        setFocusOnInput() {
            if (!isMobileSize) {
                this.node.focus();
            }
        }

        addEnterKeyListener() {
            document.addEventListener(
                'keypress',
                function(e) {
                    let key = e.which || e.keyCode;
                    if (key === 13) {
                        // 13 is the enter key
                        this.submitBtn.click();
                    }
                }.bind(this)
            );
        }

        addEventListeners() {
            this.store.events.subscribe('stateChange', () => {
                this.onStateChange();
            });

            this.submitBtn.addEventListener('click', this.onFormSubmit.bind(this));
            this.submitBtn.addEventListener('touchend', this.onFormSubmit.bind(this));

            this.startTxt.addEventListener('click', this.onFormSubmit.bind(this));
            this.startTxt.addEventListener('touchend', this.onFormSubmit.bind(this));

            this.node.addEventListener('click', this.onInputFocus.bind(this));
            this.node.addEventListener('touchend', this.onInputFocus.bind(this));

            this.node.addEventListener('blur', this.onInputBlur.bind(this));
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }

            this.addEventListeners();
        }
    }

    return {
        init() {
            return new NicknameForm().init();
        },
    };
})();

export default Object.create(NicknameForm);

//prettier-ignore
export const nicknameForm = html`
    <div class="nickname-form">
        <div class="nickname-form__header-group">
            <h1 class="nickname-form__header">What do you like to be called?</h1>
            <hr class="succinct">
        </div>

        <div class="nickname-form__input">
            <input type="text" data-js="nickname-input" placeholder="(optional)"/>
            <input class="cta-btn" data-js="nickname-submit" type="submit" value="Let's do it"/>
        </div>
        <small class="tiny-text text-magenta"><a class="text-link" href="javascript:;" data-js="start">Start the experience</a></small>
    </div>
`;
