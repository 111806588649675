export default {
    changePage(state, payload) {
        state.currentPage = payload;
        state.lastPage = state.currentPage == 0 ? 0 : state.currentPage - 1;
        return state;
    },

    toggleChallenge(state, payload) {
        state.isChallenge = payload;
        return state;
    },

    toggleScrollLock(state, payload) {
        state.isLockedState = payload;
        return state;
    },

    setLoadingState(state, payload) {
        state.isLoading = payload;
        return state;
    },

    setNickname(state, payload) {
        state.nickname = payload;
        return state;
    },

    setAgeGate(state, payload) {
        state.isGated = payload;
        return state;
    },

    setChallengePoints(state, payload) {
        state.challengePoints = payload;
        return state;
    },

    setWaveVisibility(state, payload) {
        state.showWave = payload;
        return state;
    },
};
