import { html } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import store from '@/modules/PubSub/store/index.js';
import { isMobileSize } from '../helpers/utilities';

const OH = (() => {
    class OH {
        constructor() {
            this.node = document.querySelector('.site-logo');
            this.node.utils = new DomUtils(this.node);
            this.bigOH = null;
            this.store = store;
        }

        cloneIntoBigOH() {
            this.bigOH = this.node.cloneNode(true);
            this.bigOH.classList.add('big-OH');
            this.bigOH.classList.remove('js-is-active');
            this.bigOH.querySelector('.site-logo__container').classList.remove('loading');
            this.node.insertAdjacentElement('afterend', this.bigOH);
        }

        onStateChange() {
            if (this.store.state.currentPage == 0) {
                this.node.classList.add('js-is-active');
                this.node.classList.remove('is-nav');
            }

            if (this.store.state.currentPage == 1 || document.querySelectorAll('.fp-section.active .orgasm').length) {
                this.node.classList.remove('js-is-active');
                this.node.classList.add('is-nav');
            }

            if (!this.store.state.isGated && isMobileSize) {
                this.node.classList.remove('js-is-active');
                this.node.classList.add('is-nav');
                document.querySelector('.intro__big-hello h1').classList.add('fly-away');

                setTimeout(() => {
                    document.querySelector('.intro__big-hello h1').style.width = 0 + 'px';
                }, 250);
            }

            if (this.store.state.currentPage == 3) {
                this.bigOH.classList.remove('js-is-active');
            }
        }

        onFullpageLeave() {
            setTimeout(() => {
                if (
                    document.querySelectorAll('.fp-section.active .conversion-form').length ||
                    document.querySelectorAll('.fp-section.active .ready-screen').length
                ) {
                    this.bigOH.classList.add('js-is-active');
                } else {
                    this.bigOH.classList.remove('js-is-active');
                }

                if (document.querySelectorAll('.fp-section.active .thank-you').length) {
                    this.bigOH.classList.remove('js-is-active');
                    this.node.classList.remove('is-nav');
                    this.node.classList.add('js-is-active');
                }

                if (document.querySelectorAll('.fp-section.active .thank-you').length && isMobileSize) {
                    this.node.classList.remove('js-is-active');
                    this.node.classList.add('is-nav');
                    this.node.classList.add('is-thank-you');
                }
            }, 300);
        }

        devJumpToFinale(type, e) {
            this.node.querySelector('svg').classList.add('pulse-now');

            setTimeout(() => {
                this.node.querySelector('svg').classList.remove('pulse-now');
            }, 500);

            if (!type) return;

            e.preventDefault();
            e.stopPropagation();
            window.fullpage_api.moveTo(12);
            store.dispatch('changePage', 12);
        }

        addEventListeners() {
            this.store.events.subscribe('stateChange', () => {
                this.onStateChange();
            });
            window.addEventListener('fullpageOnLeave', this.onFullpageLeave.bind(this));
            document.querySelector('[data-js="back-to-top"]').addEventListener('click', this.devJumpToFinale.bind(this, false));

            document.querySelector('[data-js="back-to-top"]').addEventListener('dblclick', this.devJumpToFinale.bind(this, false));
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }

            this.addEventListeners();
            this.cloneIntoBigOH();
        }
    }

    return {
        init() {
            return new OH().init();
        },
    };
})();

export default Object.create(OH);

//prettier-ignore
export const OHsvg = html`
    <div class="site-logo js-is-active">
        <div class="site-logo__container loading">
            <a href="javascript:;" data-js="back-to-top">
                <svg class="icon oh-logo animated pulse">
                    <use xlink:href="#icon-OH-logo" />
                </svg>
            </a>
        </div>
    </div>
`;

//prettier-ignore
export const climaxOHsvg = html`
    <div class="site-logo big-OH centered climax-OH tiny js-is-active opacity-override">
        <div class="site-logo__container ">
            <svg class="animated" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 700.01 351.8">
            <defs>
                <style>
                    .cls-1 {
                    fill: none;
                    }
            
                    .cls-2 {
                    clip-path: url(#clip-path);
                    }
            
                    .cls-3 {
                    fill: currentColor;
                    }
                </style>
                <clipPath id="clip-path" transform="translate(0 0.03)">
                    <rect class="cls-1" y="-0.03" width="700" height="351.8"/>
                </clipPath>
            </defs>
                <title>OH-logo</title>
                <g class="cls-2">
                <path class="cls-3 OH-O" d="M351.8,175.88A175.9,175.9,0,1,1,175.91,0,175.89,175.89,0,0,1,351.8,175.88" transform="translate(0 0.03)"/>
                <polygon class="cls-3 OH-H" points="543.12 10.8 543.12 94.31 537.95 94.31 537.95 94.54 529.59 94.54 529.59 94.31 524.77 94.31 524.77 10.8 367.54 10.8 367.54 341.01 524.77 341.01 524.77 257.28 529.59 257.28 529.59 257.26 537.95 257.26 537.95 257.28 543.12 257.28 543.12 341.01 700 341.01 700 303.99 700 10.8 543.12 10.8"/>
                </g>
            </svg>
        </div>
    </div>
`;
