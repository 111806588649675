import { html, render } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import store from '@/modules/PubSub/store/index.js';
import { infoSectionContent } from '@/components/infoSection/InfoSection-content';
import { infoSectionContentReveal } from '@/components/infoSection/InfoSection-content';

const InfoSection = (() => {
    class InfoSection {
        constructor() {
            this.node = document.querySelector('.screenfull__container');
            this.node.utils = new DomUtils(this.node);
            this.msg = this.node.querySelector('.ready-screen__msg');
            this.backBtn = this.node.querySelector('[data-js="reverse-reveal"]');
            this.store = store;
            this.parallaxWrapper = this.node;
            this.hasRevealed = false;
            this.hasRevealedIngredients = false;
            this.standardDelay = 2000;
        }

        revealContent() {
            if (this.hasRevealed) return;

            this.hasRevealed = true;

            let reveal = document.querySelectorAll('.fp-section.active .reveal');

            if (reveal.length) {
                /*———— -Desktop Animations- ————*/
                if (this.store.state.currentPage == 7) {
                    //chapter 3 Ride the Wave
                    setTimeout(() => {
                        if (this.hasLeft) return false;
                        reveal[0].classList.add('fade-in');
                        this.showScrollIndicator();
                    }, 2000);
                }
                //
                else if (this.store.state.currentPage !== 6) {
                    //exclude the 'ingredients reveal.

                    setTimeout(() => {
                        if (this.hasLeft) return false;
                        reveal[0].classList.add('fade-in');

                        //then show the scroll indicator
                        this.showScrollIndicator();
                    }, this.standardDelay);
                }

                //define how long to wait before to unlock the scroll
                setTimeout(() => {
                    this.completeChallenge();
                }, 1500);
            } else if (this.store.state.currentPage == 6) {
                this.ingredientReveal();
                this.completeChallenge();
            } else if (this.store.state.currentPage == 9) {
                //last info screen
                setTimeout(() => {
                    if (this.hasLeft) return false;
                    this.showScrollIndicator();
                    this.completeChallenge();
                }, 2500);
            } else {
                this.completeChallenge();
            }
        }

        showScrollIndicator() {
            setTimeout(() => {
                if (this.hasLeft) return;
                window.dispatchEvent(new Event('scrollIndicatorShow'));
            }, this.standardDelay / 2);
        }

        blockCompletion() {
            this.hasLeft = true;
        }

        completeChallenge() {
            let completionTimeout = setTimeout(() => {
                if (this.hasLeft) return;
                store.dispatch('setChallengePoints', this.store.state.currentPage - 2);
                window.dispatchEvent(new Event('triggerDebouncedAdvance'));
            }, 800);

            this.completionTimeout = completionTimeout;
        }

        ingredientReveal() {
            //special reveal for page 6
            if (this.hasRevealedIngredients) return;

            let revealDiv = document.querySelector('.fp-section.active .info-section__text');
            let afterthought = document.querySelector('.fp-section.active .info-section__afterthought');

            revealDiv.classList.add('reveal');
            afterthought.classList.add('reveal');

            //stagger reveal the first two
            setTimeout(() => {
                revealDiv.classList.add('fade-in');
            }, 250);

            setTimeout(() => {
                afterthought.classList.add('fade-in');

                this.longTimeout = setTimeout(() => {
                    //then after the long delay
                    this.renderReveal(infoSectionContentReveal, revealDiv);

                    //then give the scroll indicator
                    setTimeout(() => {
                        if (this.hasLeft) return false;
                        window.dispatchEvent(new Event('scrollIndicatorShow'));
                    }, 3000);
                }, 6000);
            }, 2500);

            this.hasRevealedIngredients = true;
        }

        renderReveal(content, target) {
            //fade-out
            let revealDiv = document.querySelector('.fp-section.active .info-section__text');
            let afterthought = document.querySelector('.fp-section.active .info-section__afterthought');

            if (revealDiv && afterthought) {
                revealDiv.classList.add('fade-out');
                afterthought.classList.add('fade-out');

                setTimeout(() => {
                    //fade-in
                    render(content, target);
                    revealDiv.classList.remove('fade-out');
                    revealDiv.classList.add('ingredient-reveal');
                    revealDiv.classList.add('fade-in');

                    this.backBtn = this.node.querySelector('[data-js="reverse-reveal"]');
                    this.backBtn.addEventListener('click', this.onBackBtnClick.bind(this));
                }, 800);
            }
        }

        reverseReveal(content, target) {
            let revealDiv = document.querySelector('.fp-section.active .info-section__text');
            let afterthought = document.querySelector('.fp-section.active .info-section__afterthought');

            if (revealDiv && afterthought) {
                revealDiv.classList.add('fade-out');
                afterthought.classList.add('fade-out');

                setTimeout(() => {
                    //fade-in
                    render(content, target);
                    revealDiv.classList.remove('ingredient-reveal');
                    afterthought.classList.remove('fade-out');
                    revealDiv.classList.remove('fade-out');
                    revealDiv.classList.add('fade-in');
                }, 800);
            }
        }

        onBackBtnClick() {
            let revealDiv = document.querySelector('.fp-section.active .info-section__text');
            this.reverseReveal(infoSectionContent[1].text, revealDiv);
        }

        onFullpageLeave() {
            this.blockCompletion();
        
            if(this.longTimeout){
                clearTimeout(this.longTimeout);
            }
        
            setTimeout(() => {
                if (document.querySelectorAll('.fp-section.active .info-section').length) {
                    this.hasLeft = false;
                    this.hasRevealed = false;

                    this.revealContent();
                }
            }, 400);
        }

        addEventListeners() {
            window.addEventListener('fullpageOnLeave', this.onFullpageLeave.bind(this));
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }
            this.addEventListeners();
        }
    }

    return {
        init() {
            return new InfoSection().init();
        },
    };
})();

export default Object.create(InfoSection);

//prettier-ignore
export const infoSection = (content, index) => html`
<div class="info-section info-section-${index + 1}">
        
    <div class="info-section__reading-bounds">
        <div class="info-section__container vh-fix">

            <div class="info-section__gif">
                ${content.gif}
            </div>    

            <div class="info-section__text animated">
                ${content.text}
            </div>

            <div class="info-section__afterthought animated">
                ${content.afterthought}
            </div>
        </div>
    </div>

    <div class="info-section__image">
    <div class="info-section__image-circle"></div>
    <img src="${content.imageSrc}" />
    </div>
</div>
`;
