import { html } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import store from '@/modules/PubSub/store/index.js';

const ScrollIndicator = (() => {
    class ScrollIndicator {
        constructor() {
            this.node = document.querySelector('.scroll-indicator');
            this.node.utils = new DomUtils(this.node);
            this.store = store;
            this.revealTimeout = null;
            this.isLoading = true;
        }

        onStateChange() {
            if (this.store.state.isGated == false && this.store.state.currentPage == 0) {
                //timeout syncs reveal up with intro animation

                if (this.isLoading) {
                    this.isLoading = false;

                    const revealTimeout = setTimeout(() => {
                        this.show();
                    }, 2000);

                    this.revealTimeout = revealTimeout;
                }
            }
            //
            if (this.store.state.currentPage == 1 || this.store.state.currentPage == 11) {
                clearTimeout(this.revealTimeout);
                this.hide();
            }
            //
            if (this.store.state.currentPage == 3) {
                this.hide();
                setTimeout(() => {
                    this.node.classList.remove('big-left');
                }, 500);
            }
        }

        onFullpageLeave() {
            setTimeout(() => {
                if (document.querySelector('.fp-section.active .ready-screen')) {
                    this.node.classList.add('big-left');

                    setTimeout(() => {
                        this.show();
                    }, 500);
                }
            }, 300);
        }

        hide() {
            this.node.classList.remove('js-is-active');
        }

        show() {
            this.node.classList.add('js-is-active');
            if (document.querySelector('.fp-section.active .info-section')) {
                this.node.classList.add('smaller');
            } else {
                this.node.classList.remove('smaller');
            }
        }

        addEventListeners() {
            this.store.events.subscribe('stateChange', () => {
                this.onStateChange();
            });

            window.addEventListener('scrollIndicatorShow', this.show.bind(this));
            window.addEventListener('fullpageOnLeave', this.hide.bind(this));
            window.addEventListener('fullpageOnLeave', this.onFullpageLeave.bind(this));
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }

            this.addEventListeners();
        }
    }

    return {
        init() {
            return new ScrollIndicator().init();
        },
    };
})();

export default Object.create(ScrollIndicator);

//prettier-ignore
export const scrollIndicator = (isMobile) => html`
    <div class="scroll-indicator">
        <div class="scroll-indicator__container loading">
            <a href="javascript:;" data-js="scroll-indicator">
                ${isMobile ? 
                    html`
                    <svg width="89px" height="194px" viewBox="0 0 89 194" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <!-- Generator: Sketch 52.6 (67491) - http://www.bohemiancoding.com/sketch -->
                        <title>mobile_scrollIndicator</title>
                        <desc>Created with Sketch.</desc>
                        <g id="Page-1" stroke="currentColor" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g id="mobile_scrollIndicator" transform="translate(2.000000, 2.000000)" stroke="currentColor" stroke-width="5">
                                <path d="M66,169.7 C63.4,172.3 47.1,188.6 47.1,188.6 L27.9,169.4" id="Stroke-3"></path>
                                <polyline id="Path" points="36.3 66 74.2 66.9 84.2 104.1 78.5 121.1 80.8 129.7"></polyline>
                                <path d="M48.9,68.8 L52.8,83.2" id="Path"></path>
                                <path d="M61.9,69.2 L65.8,83.5" id="Path"></path>
                                <path d="M39.9,82.9 L30.7,48.4 C29.6,44.4 25.5,42 21.5,43.1 C17.5,44.2 15.1,48.3 16.2,52.3 L26.7,91.6 C27.7,95.2 25.5,98.9 21.9,99.9 L21.5,100 L14.6,95.3 C10.8,92.7 5.7,93.7 3.2,97.5 L2.3,98.8 C-0.3,102.6 0.7,107.7 4.5,110.2 L36.9,132.3 L39.2,140.8" id="Path"></path>
                            </g>
                        </g>
                    </svg>            
                    
                    `

                    
                    
                    :
                    html`
                    
                        <svg width="65px" height="194px" viewBox="0 0 65 194" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <!-- Generator: Sketch 52.6 (67491) - http://www.bohemiancoding.com/sketch -->
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                <g id="Artboard" stroke="currentColor" stroke-width="5">
                                    <g id="Group-9" transform="translate(2.000000, 2.000000)">
                                        <path d="M30.2839,150.1048 C13.8349,150.1048 0.4999,136.7698 0.4999,120.3208 L0.4999,67.5058 C0.4999,51.0578 13.8349,37.7228 30.2839,37.7228 C46.7329,37.7228 60.0679,51.0578 60.0679,67.5058 L60.0679,120.3208 C60.0679,136.7698 46.7329,150.1048 30.2839,150.1048 Z" id="Stroke-1"></path>
                                        <path d="M49.3532,169.7474 C46.7322,172.3664 30.4602,188.6394 30.4602,188.6394 L11.2142,169.3934" id="Stroke-3" data-js="arrow-down"></path>
                                        <path d="M11.2145,19.3924 L30.1085,0.4994 C30.1085,0.4994 49.2645,19.6574 49.3535,19.7464" id="Stroke-5" data-js="arrow-up"></path>
                                        <path d="M30.2839,55.075 L30.2839,70.429" id="Stroke-7"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>          
                    `
                
                }
            </a>
        </div>  
    </div>
`;
