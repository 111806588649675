import store from '@/modules/PubSub/store/index.js';
import { isMobile, isMobileSize } from '@/helpers/utilities';
import { fnAdvanceSection } from '@/modules/Screenfull';

const MasterController = (() => {
    class MasterController {
        constructor() {
            this.node = null;
            this.store = store;
            this.currentPage = this.store.state.currentPage;
            this.lastPage = this.store.state.lastPage;
            this.debouncedAdvanceHandler = null;
            this.doneLoading = false;
            this.canScrollUp = false;
            this.canScrollDown = true;
            this.justBacktracked = false;
            this.touches = {
                touchstart: { x: -1, y: -1 },
                touchmove: { x: -1, y: -1 },
                touchend: false,
                direction: 'undetermined',
            };
        }

        setTimelineStep(direction) {
            if (!direction) return;

            /*———— -Step 0: scroll down- ————*/
            if (this.currentPage == 0) {
                //then on page 1 do this--
                this.setAllowScroll(true, false);
                this.allowScroll(true);
            }

            /*———— -Step 1: set your Nickname- ————*/
            if (this.currentPage == 1 && direction == 'forward') {
                //then on page 2 do this--
                this.setAllowScroll(true, true);
                this.allowScroll(true);
            }

            /*———— -Step 2: Ready Screen (scroll down)- ————*/
            if (this.currentPage == 2 && direction == 'forward') {
                //then on page 3 (challenge)--
                this.setAllowScroll(); //aka. (false, false)
            }

            if (this.currentPage == 3 && direction == 'backward') {
                this.setAllowScroll(true, true);
            }

            /*———— -Step 3-9:  Wave Challenge and Info- ————*/
            if (this.currentPage >= 3) {
            }

            //increment/decrement the page number
            if (direction == 'forward') {
                clearTimeout(this.blockDoubleBack);
                this.currentPage = this.currentPage + 1;
            }

            if (direction == 'backward') {
                this.allowScroll(true);
                this.currentPage = this.currentPage - 1;
            }

            store.dispatch('changePage', this.currentPage);
        }

        debouncedAdvance() {
            const debouncedAdvanceHandler = (e) => {
                let currentStepIndex = window.fullpage_api.getActiveSection().index;

                //mouse events
                if (e.deltaY > 8 || e.type == 'click') {
                    if (!this.canScrollDown) return;

                    this.removeScrollEventListeners();
                    this.advance();
                } else if (e.deltaY <= -8 && currentStepIndex >= 1) {
                    if (!this.canScrollUp) return;

                    this.removeScrollEventListeners();
                    this.receed();
                    window.dispatchEvent(new Event('backwardsMove'));
                }

                //touch events
                else if (typeof e.touches !== 'undefined') {
                    let direction = this.getTouchDirection(e);

                    if (direction == 'up') {
                        if (!this.canScrollDown) return;

                        this.removeScrollEventListeners();
                        this.advance();
                    }

                    if (direction == 'down' && currentStepIndex >= 1) {
                        if (!this.canScrollUp) return;

                        this.removeScrollEventListeners();
                        this.receed();
                        window.dispatchEvent(new Event('backwardsMove'));
                    }
                }
            };

            this.debouncedAdvanceHandler = debouncedAdvanceHandler;
        }

        getTouchDirection(event) {
            var touch;
            if (typeof event !== 'undefined') {
                if (typeof event.touches !== 'undefined') {
                    touch = event.touches[0];

                    switch (event.type) {
                        case 'touchstart':
                        case 'touchmove':
                            this.touches[event.type].x = touch.pageX;
                            this.touches[event.type].y = touch.pageY;
                            break;
                        case 'touchend':
                            this.touches[event.type] = true;
                            var x = this.touches.touchstart.x - this.touches.touchmove.x,
                                y = this.touches.touchstart.y - this.touches.touchmove.y;
                            if (x < 0) x /= -1;
                            if (y < 0) y /= -1;

                            if (y > 75) {
                                if (x > y) this.touches.direction = this.touches.touchstart.x < this.touches.touchmove.x ? 'right' : 'left';
                                else this.touches.direction = this.touches.touchstart.y < this.touches.touchmove.y ? 'down' : 'up';
                                return this.touches.direction;
                            }
                            break;
                    }
                }
            }
        }

        advance() {
            if (!this.canScrollDown) return;
            this.justBacktracked = false;

            fnAdvanceSection();
            this.setTimelineStep('forward');
        }

        receed() {
            if (!this.canScrollUp || this.justBacktracked) return;
            this.justBacktracked = true;
            this.blockDoubleBack();

            window.dispatchEvent(new Event('backwardsMove'));
            window.fullpage_api.moveSectionUp();
            this.setTimelineStep('backward');
        }

        blockDoubleBack() {
            const doubleBackTimeout = setTimeout(() => {
                this.justBacktracked = false;
                this.addScrollEventListeners();
            }, 800);

            this.doubleBackTimeout = doubleBackTimeout;
        }

        setAllowScroll(up, down) {
            this.canScrollUp = up;
            this.canScrollDown = down;
        }

        allowScroll(waitForSettle) {
            if (waitForSettle) {
                this.waitForTransitionHandler = this.onWaitForTransitionEnd.bind(this);
                this.node.addEventListener('transitionend', this.waitForTransitionHandler);
            } else {
                this.addScrollEventListeners();
            }
        }

        allowAdvance() {
            if (this.node.querySelector('.fp-section.active .info-section')) {
                this.setAllowScroll(true, true);
                this.allowScroll();
            }
            //
            else {
                this.setAllowScroll(false, true);
                this.allowScroll();
            }
        }

        onAllowLeaveFromTop(bool) {
            if(bool == false){
                this.setAllowScroll(false, false);    
                return;
            }

            this.setAllowScroll(true, false);
            this.allowScroll();
        }


        addScrollEventListeners() {
            //add event listeners and handlers to be executed only once after this method is called
            document.addEventListener('wheel', this.debouncedAdvanceHandler);
            document.querySelector('[data-js="scroll-indicator"]').addEventListener('click', this.debouncedAdvanceHandler);

            //touch events
            this.node.addEventListener('touchstart', this.debouncedAdvanceHandler);
            this.node.addEventListener('touchmove', this.debouncedAdvanceHandler);
            this.node.addEventListener('touchend', this.debouncedAdvanceHandler);
        }

        removeScrollEventListeners() {
            document.removeEventListener('wheel', this.debouncedAdvanceHandler);

            document.querySelector('[data-js="scroll-indicator"]').removeEventListener('click', this.debouncedAdvanceHandler);

            //touch events
            this.node.removeEventListener('touchstart', this.debouncedAdvanceHandler);
            this.node.removeEventListener('touchmove', this.debouncedAdvanceHandler);
            this.node.removeEventListener('touchend', this.debouncedAdvanceHandler);
        }

        onStateChange() {
            //setup initial scroll listener for intro sreen only
            if (this.store.state.isLoading == false && this.currentPage == 0) {
                if (this.doneLoading) return;
                this.doneLoading = true;

                this.node = document.querySelector('.screenfull__container');

                setTimeout(() => {
                    this.debouncedAdvance();
                    this.allowScroll();
                    this.setAllowScroll(false, true);
                }, 1000);
            }
        }

        onSetNickname() {
            fnAdvanceSection();
            this.setTimelineStep('forward');
        }

        onWaitForTransitionEnd(e) {
            if (e.propertyName !== 'transform' && e.currentTarget !== this.node) return;
            this.node.removeEventListener('transitionend', this.waitForTransitionHandler);
            this.addScrollEventListeners();
        }

        onFullpageTransitionEnd(e) {
            if (e.propertyName !== 'transform' || e.currentTarget !== this.node) return;

            this.node.removeEventListener('transitionend', this.transitionEndHandler);

            if (this.currentPage == 0) {
                this.setAllowScroll(false, true);
            }

            //nickname screen
            if (this.node.querySelector('.fp-section.active .nickname-form')) {
                this.setAllowScroll(true, false);
            }

            //ready screen
            if (this.currentPage == 2) {
                this.setAllowScroll(true, true);
            }

            //challenges
            if (this.node.querySelector('.fp-section.active .challenge-prompt')) {
                //give them a chance to scroll back up
                setTimeout(() => {
                    this.allowAdvance();
                    this.setAllowScroll(true, false);
                });

                //then lock the whole thang down if we are still in the challenge
                setTimeout(() => {
                    if (this.node.querySelector('.fp-section.active .challenge-prompt')) {
                        this.setAllowScroll(false, false);
                    }
                }, 1000);
            }

            //info-sections
            if (this.node.querySelector('.fp-section.active .info-section')) {
                if (isMobile || isMobileSize) return;
                this.allowAdvance();
                this.setAllowScroll(true, false);
            }
        }

        onFullpageLeave() {
            this.transitionEndHandler = this.onFullpageTransitionEnd.bind(this);
            this.node.addEventListener('transitionend', this.transitionEndHandler);
        }

        addEventListeners() {
            this.store.events.subscribe('stateChange', () => {
                this.onStateChange();
            });
            window.addEventListener('fullpageOnLeave', this.onFullpageLeave.bind(this));
            window.addEventListener('triggerDebouncedAdvance', this.allowAdvance.bind(this));
            window.addEventListener('updateNickname', this.onSetNickname.bind(this));
            window.addEventListener('allowLeaveFromTop', this.onAllowLeaveFromTop.bind(this));
            window.addEventListener('disallowLeaveFromTop', this.onAllowLeaveFromTop.bind(this, false));
        }

        init() {
            this.addEventListeners();
        }
    }

    return {
        init() {
            return new MasterController().init();
        },
    };
})();

export default Object.create(MasterController);
