import { isMobile, isMobileSize } from '../helpers/utilities';
import store from '@/modules/PubSub/store/index.js';

const BrowserTabs = (() => {
    class BrowserTabs {
        constructor() {
            this.hidden = null;
            this.visibilityChange = null;
            this.store = store;
            this.title = document.title;
            this.nickname = null;
            this.commaNickname = null;
            this.phraseBook = [];

            /*———— -Emojis- ————*/
            this.strawberry = String.fromCodePoint(0x1f353);
            this.eyes = String.fromCodePoint(0x1f440);
            this.sparklingHeart = String.fromCodePoint(0x1f496);
            this.cryingFace = String.fromCodePoint(0x1f622);
        }

        getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        }

        getRandomPhrase() {
            let whichMsg = this.getRandomInt(this.phraseBook.length);
            return this.phraseBook[whichMsg];
        }

        setPhrases() {
            this.commaNickname = this.nickname ? `, ${this.nickname}` : '';
            this.commaAfterNickname = this.nickname ? `${this.nickname}, ` : '';
            this.phraseBook = [
                this.strawberry + ` PLEASE me${this.commaNickname}`,
                this.eyes + ` ${this.commaAfterNickname}COME back`,
                this.sparklingHeart + ` LOVE me${this.commaNickname}`,
                this.cryingFace + ` OH how I miss you${this.commaNickname}`
            ];
        }

        getEventTypePerQuirk() {
            if (typeof document.hidden !== 'undefined') {
                // Opera 12.10 and Firefox 18 and later support
                this.hidden = 'hidden';
                this.visibilityChange = 'visibilitychange';
                //
            } else if (typeof document.msHidden !== 'undefined') {
                this.hidden = 'msHidden';
                this.visibilityChange = 'msvisibilitychange';
                //
            } else if (typeof document.webkitHidden !== 'undefined') {
                this.hidden = 'webkitHidden';
                this.visibilityChange = 'webkitvisibilitychange';
            }
        }

        onVisibilityChange() {
            if (document[this.hidden]) {
                console.log('document is hidden');
                document.title = this.getRandomPhrase();
            } else {
                document.title = this.title;
            }
        }

        onStateChange() {
            if (this.store.state.nickname && this.store.state.nickname !== 'blank') {
                //don't run if the nickname hasn't changed
                if (this.nickname == this.store.state.nickname) return;
                this.nickname = this.store.state.nickname;

                this.setPhrases();
            }
        }

        addEventListeners() {
            this.store.events.subscribe('stateChange', () => {
                this.onStateChange();
            });

            document.addEventListener(this.visibilityChange, this.onVisibilityChange.bind(this));
        }

        shouldInit() {
            if (!isMobile && !isMobileSize) {
                return true;
            }
        }

        init() {
            if (!this.shouldInit()) return;
            this.getEventTypePerQuirk();
            this.addEventListeners();
            this.setPhrases();
        }
    }

    return {
        init() {
            return new BrowserTabs().init();
        },
    };
})();

export default Object.create(BrowserTabs);
