import { html, render } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import store from '@/modules/PubSub/store/index.js';
import { nicknameText } from './NicknameText';

const ThankYou = (() => {
    class ThankYou {
        constructor() {
            this.node = document.querySelector('.thank-you');
            this.node.utils = new DomUtils(this.node);
            this.store = store;
            this.goAgain = document.querySelector('[data-js="refresh"]');
            this.msg = this.node.querySelector('.thank-you__header');
            //this.copyField = this.node.querySelector('[data-js="copy-text"]');
            //this.copyText = this.copyField.value + '?utm_source=clipboard';
            //this.copyLink = this.node.querySelector('[data-js="copy-link"]');
            this.nicknameSet = false;
        }

        onStateChange() {
            if (this.store.state.nickname) {
           //     this.renderNickname();
            }
        }

        refreshPage() {
            let url = window.location.href;
            window.location.replace(url);
        }

        renderNickname() {
            if (this.nicknameSet) return;

            render(nicknameText('Thanks', store.state.nickname), this.msg);
            this.nicknameSet = true;
        }

        onCopyToClipboard() {
            this.copyToClipboard(this.copyText);
            this.copyField.classList.add('bg-magenta');
            this.copyField.classList.add('text-white');
            this.copyField.setAttribute('value', 'Copied to your clipboard.');
        }

        copyToClipboard(str) {
            /* ———https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f——— */
            const el = document.createElement('textarea'); // Create a <textarea> element

            let storeContentEditable = el.contentEditable;
            let storeReadOnly = el.readOnly;

            el.value = str; // Set its value to the string that you want copied
            el.contentEditable = true;
            el.readOnly = false;
            el.setAttribute('readonly', false); // Make it readonly false for iOS compatability
            el.setAttribute('contenteditable', true); // Make it editable for iOS
            el.style.position = 'absolute';
            el.style.left = '-9999px'; // Move outside the screen to make it invisible
            document.body.appendChild(el); // Append the <textarea> element to the HTML document
            const selected =
                document.getSelection().rangeCount > 0 // Check if there is any content selected previously
                    ? document.getSelection().getRangeAt(0) // Store selection if found
                    : false; // Mark as false to know no selection existed before
            el.select(); // Select the <textarea> content
            el.setSelectionRange(0, 999999);
            document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
            document.body.removeChild(el); // Remove the <textarea> element
            if (selected) {
                // If a selection existed before copying
                document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
                document.getSelection().addRange(selected); // Restore the original selection
            }

            el.contentEditable = storeContentEditable;
            el.readOnly = storeReadOnly;
        }

        addEventListeners() {
            this.store.events.subscribe('stateChange', () => {
                this.onStateChange();
            });

            //this.copyLink.addEventListener('click', this.onCopyToClipboard.bind(this));
            this.goAgain.addEventListener('click', this.refreshPage.bind(this));
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }

            this.addEventListeners();
        }
    }

    return {
        init() {
            return new ThankYou().init();
        },
    };
})();

export default Object.create(ThankYou);


//prettier-ignore
export const thankYou = html`
    <div class="thank-you">
        <div class="thank-you__container">
                
            <div class="intro__big-hello">
                <h1 class="huge">yes.</h1>
            </div>
            
            <div class="thank-you__message">
                <div class="thank-you__header-group">
                    <h1 class="thank-you__header">Limited time only - Find yours now</h1>
                    <hr class="succinct">
                    
                    <div class="location-wrapper">
                        <div class="location-list">
                            <div><a href="https://letsascend.com/menu/maryland/aberdeen-rec/products?search=curio" target="_blank">Ascend Wellness - Aberdeen</a></div>
                            <div><a href="https://letsascend.com/menu/maryland/ellicott-city-rec/products?search=%20curio" target="_blank">Ascend Wellness - Ellicot City</a></div>
                            <div><a href="https://letsascend.com/menu/maryland/laurel-rec/products?search=curio" target="_blank">Ascend Wellness - Laurel</a></div>
                            <div><a href="https://letsascend.com/menu/maryland/crofton-rec/products?search=curio&brand=Curio" target="_blank">Ascend Wellness - Crofton</a></div>
                            <div><a href="https://bloommedicinals.com/maryland/germantown-dispensary-md/germantown-dispensary-rec-menu/" target="_blank">Bloom Medicinals</a></div>
                            <div><a href="https://menu.chesacanna.com/stores/chesacanna1/brands/curio-wellness" target="_blank">Chesacanna</a></div>
                            <div><a href="https://curaleaf.com/shop/maryland/curaleaf-md-columbia-au/products?" target="_blank">Curaleaf - Columbia</a></div>
                            <div><a href="https://curaleaf.com/shop/maryland/curaleaf-dispensary-md-frederick-au/products?" target="_blank">Curaleaf - Frederick</a></div>
                            <div><a href="https://curaleaf.com/shop/maryland/curaleaf-md-gaithersburg-montgomery-village-au/products?" target="_blank">Curaleaf - Montgomery Village</a></div>
                            <div><a href="https://curaleaf.com/shop/maryland/curaleaf-md-reisterstown/products?k=curio" target="_blank">Curaleaf - Reisterstown</a></div>
                            <div><a href="https://dispensaryworks.com/order-online/?dtche%5Bpath%5D=products&dtche%5Bsearch%5D=curio" target="_blank">Dispensary Works</a></div>
                            <div><a href="https://fardotter.com/shop-now/?dtche%5Bpath%5D=brands%2Fcurio-wellness" target="_blank">Far & Dotter</a></div>
                            <div><a href="https://www.iheartjane.com/stores/5444/gleaf-md-frederick-adult-use" target="_blank">gLeaf Wellness - Frederick</a></div>
                            <div><a href="https://www.iheartjane.com/stores/839/gold-leaf-md?refinementList%5Bbrand%5D%5B0%5D=Curio%20Wellness" target="_blank">Gold Leaf</a></div>
                            <div><a href="https://visitgreengoods.com/frederick-md-menu-med/?filters%5Bbrand%5D%5B%5D=Curio%20Wellness" target="_blank">Green Goods - Frederick</a></div>
                            <div><a href="https://visitgreengoods.com/baltimore-md-menu-med/?filters%5Bbrand%5D%5B%5D=Curio%20Wellnesshttps://hampden.ethoscannabis.com/stores/mission-hampden" target="_blank">Green Goods - Baltimore</a></div>
                            <div><a href="https://www.gpwellness.com/laurel-menu?dtche%5Bpath%5D=brands%2Fcurio-wellness" target="_blank">Green Point Wellness - Laurel</a></div>
                            <div><a href="https://www.gpwellness.com/linthicum-menu?dtche%5Bpath%5D=brands%2Fcurio-wellness" target="_blank">Green Point Wellness - Linthicum</a></div>
                            <div><a href="https://www.gpwellness.com/millersville-menu" target="_blank">Green Point Wellness - Millersville</a></div>
                            <div><a href="https://greenlabsmd.com/pages/medical-menu?dtche%5Bpath%5D=brands%2Fcurio-wellness" target="_blank">GreenLabs</a></div>
                            <div><a href="https://www.leafly.com/dispensary-info/health-for-life---baltimore/menu?q=curio%20wellness" target="_blank">Health for Life - Baltimore</a></div>
                            <div><a href="https://healthforlifedispensaries.com/maryland/bethesda/?dtche%5Bpath%5D=brands%2Fcurio-wellness" target="_blank">Health for Life - White Marsh</a></div>
                            <div><a href="https://maryandmain.com/recreational-menu/?dtche%5Bpath%5D=brands%2Fcurio-wellness" target="_blank">Mary & Main</a></div>
                            <div><a href="https://pharmkent.com/menu/?dtche%5Bpath%5D=brands%2Fcurio-wellness" target="_blank">PharmKent</a></div>
                            <div><a href="https://weedmaps.com/dispensaries/positive-energy?filter%5BlicenseType%5D=recreational&filter%5BbrandSlugs%5D%5B%5D=curio-wellness" target="_blank">Positive Energy</a></div>
                            <div><a href="https://baltimore.remedymaryland.com/stores/remedy-baltimore/brands/curio-wellness" target="_blank">Remedy - Baltimore</a></div>
                            <div><a href="https://columbia.remedymaryland.com/stores/remedy-columbia/brands/curio-wellness" target="_blank">Remedy - Columbia</a></div>
                            <div><a href="https://risecannabis.com/dispensary-menu/maryland/hagerstown-adult-use-rec-menu/" target="_blank">RISE - Hagerstown</a></div>
                            <div><a href="https://shop.starbuds.us/menu/The Apothecarium - Salisbury</a>https://www.iheartjane.com/embed/stores/5475/menu?filters%5Bbrand%5D%5B%5D=Curio%20Wellness" target="_blank">Starbuds</a></div>
                            <div><a href="https://dutchie.com/stores/the-dispensary-md/brands/curio-wellness" target="_blank">The Dispensary</a></div>
                            <div><a href="https://trilogy.health/menu/" target="_blank">Trilogy Wellness of Maryland</a></div>
                            <div><a href="https://www.verilife.com/brands/curio" target="_blank">Verilife - New Market</a></div>
                            <div><a href="https://www.verilife.com/brands/curio" target="_blank">Verilife - Westminster</a></div>
                        </div>
                    </div>
                
                    <p class="small">Availability may be subject to change. For inquiries about current availability, please contact your local dispensary.</p>
                
                </div>
                
                

            </div>
        </div>
    </div>

    <div class="go-again" >
        <a data-js="refresh" href="javascript:;">
            <svg width="43px" height="25px" viewBox="0 0 43 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!-- Generator: Sketch 52.6 (67491) - http://www.bohemiancoding.com/sketch -->
            <title>Stroke-5</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="somehing" transform="translate(2.000000, 3.000000)" stroke="#000000" stroke-width="5">
                    <path d="M0.2145,19.3924 L19.1085,0.4994 C19.1085,0.4994 38.2645,19.6574 38.3535,19.7464" id="Stroke-5"></path>
                </g>
            </g>
        </svg>

            <p>Wanna go again?</p>
        </a>
    </div>
`;
