import { html } from 'lit-html';

export const infoSectionContent = [
    {
        gif: html`
            <img class="contained" src="dist/images/hips.gif" />
        `,
        text: html`
            <h1>Intimacy Oil.</h1>
            <hr class="succinct" />
            <h2 class="text-magenta">The O.G. OH</h2>
            <p>
            The arousing original OH product that inspired the entire collection. Featuring a harmonious blend of shea butter, aromatic vanilla, and clary sage.
            </p>
        `,
        afterthought: html`
            <p class="large text-magenta reveal">
            Pleasurable glide and unique tingling sensation<br><br>
            All-natural, vegan<br><br>
            30mg THC per mL
            </p>
        `,
        imageSrc: 'dist/images/Oh-Oil.png',
    },

    {
        gif: html`
            <div class="image-content-2-up">
                <img src="dist/images/mouth.gif" />
                <h1>Chews</h1>
            </div>
        `,
        text: html`
            <hr class="succinct" />
            <h2 class="text-magenta">Nibble Naughty</h2>
            <p>
            A mouthwatering chew formulated with Golden Strawberry terpenes, beloved for helping boost libido and enhance bedroom pleasure.
            </p>
        `,
        imageSrc: 'dist/images/Oh-Chew.png',
        afterthought: html`
            <p class="large text-magenta">
            “Bedroom Berry” flavor<br><br>
            Convenient resealable pouch<br><br>
            5mg THC per chew
            </p>
        `,
    },

    {
        gif: html`
            <img class="contained" src="dist/images/waves.gif" />
        `,
        text: html`
            <h1>Vape</h1>
            <hr class="succinct" />
            <h2 class="text-magenta">Sexy Hits</h2>
            <p>
            Designed to quickly deliver the potential effects of arousal, stimulation, and relaxation, OH vapes are formulated from our Golden Strawberry strain.
            </p>
        `,
        afterthought: html`
            <p class="large text-magenta reveal">
            CO2 Distillate<br><br>
            Rich in Limonene and beta-Caryophyllene<br><br>
            0.5g
            </p>
        `,
        imageSrc: 'dist/images/Oh-vape.png',
    },

    {
        gif: html`
            <img class="contained" src="dist/images/leaf.gif" />
        `,
        text: html`
            <h1>Pre-Rolls</h1>
            <hr class="succinct" />
            <h2 class="text-magenta">Burnin’ Love</h2>
            <p>
            Made from our Golden Strawberry strain, perfectly rolled to overcome ED, stress, and boost your libido.
            </p>
        `,
        afterthought: html`
            <p class="large text-magenta reveal">
            Ideal for date night, 
            but perfect anytime<br><br>
            Rich in Limonene and beta-Caryophyllene<br><br>
            2-pack (2 x 0.5g pre-rolls)
            </p>
        `,
        imageSrc: 'dist/images/Oh-Pre-roll.png',
    },
];

export const infoSectionContentReveal = html`
    <hr class="succinct" />
    <p class="large text-magenta">
        It's what's on the inside that counts. So you're never left high and dry.
    </p>

    <a href="javascript:;" class="info-section__back-arrow desktop-only" data-js="reverse-reveal">
        <svg class="icon back-arrow animated pulse">
            <use xlink:href="#icon-arrow-back" />
        </svg>

        <span> back </span>
    </a>
`;
