import { html, render } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import store from '@/modules/PubSub/store/index.js';
import { isMobileSize } from '@/helpers/utilities';

const ConversionForm = (() => {
    class ConversionForm {
        constructor() {
            this.node = document.querySelector('[data-js="conversion-form"]');
            this.nodeHeight = this.node.scrollHeight;
            this.conversionInput = document.querySelector('[data-js="conversion-submit"]');
            this.header = document.querySelector('.conversion-form__header-group');
            this.headerHeight = this.header.scrollHeight;
            this.node.utils = new DomUtils(this.node);
            this.store = store;
            this.dedupeMsg = document.querySelector('[data-js="dedupe-msg"]');
            this.hasSubmitted = false;
            this.hasEnterKeyBinding = false;
            this.iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        }

        validateEmail(email) {
            let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return regex.test(email);
        }

        onFormSubmit(e) {
            e.preventDefault();

            document.querySelector('.conversion-form').classList.add('fade-out');
            window.fullpage_api.moveSectionDown();
        }

        collapseHeader(close) {
            this.header.style.opacity = 1;
            this.header.style.maxHeight = this.headerHeight + 'px';
            this.node.style.height = this.nodeHeight + 'px';
            document.querySelector('.conversion-form__input').classList.remove('stay-visible');

            if (close) {
                this.header.style.opacity = 0;
                setTimeout(() => {
                    //document.querySelector('.conversion-form__input').classList.add('stay-visible');
                    this.header.style.maxHeight = '0px';

                    if (this.iOS) {
                        this.header.style.maxHeight = '150px';
                    }
                }, 300);
            }
        }

        addEventListeners() {
            this.node.addEventListener('submit', this.onFormSubmit.bind(this));

            if (isMobileSize) {
                this.conversionInput.addEventListener('click', this.collapseHeader.bind(this, true));
                this.conversionInput.addEventListener('blur', this.collapseHeader.bind(this, false));
            }
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }

            this.addEventListeners();
        }
    }

    return {
        init() {
            return new ConversionForm().init();
        },
    };
})();

export default Object.create(ConversionForm);

//prettier-ignore
export const conversionForm = html`
    <div class="conversion-form animated reveal fade-in">
        <div class="conversion-form__container">
            <div class="product-image">
                <img class="intro__product-image" src="dist/images/Oh-Product-Line-Up_4-Products.png" alt="A bottle of Curio Oh Lubricant" /> 
            </div> 
            
            <div class="conversion-form-wrapper">
                <div class="conversion-form__header-group">
                    <h1 class="conversion-form__header">The OH Intimacy Line from Curio</h1>
                    <hr class="succinct">

                    <p>Or as we like to call it, <strong class="text-magenta">The Affection Collection.</strong><br>
                    Try one, try them all, try to control yourself (JK, go for it).</p>
                    <ul style="margin-top: 1em; margin-bottom: 2em;">
                        <li class="text-magenta" style="display: block"><a href="https://curiowellness.com/product/curio-intimacy-oil-3-0ml/" target="_blank"><strong>OH Intimacy Oil</strong></a></li>
                        <li class="text-magenta" style="display: block"><a href="https://curiowellness.com/product/oh-bedroom-berry-terpene-infused-chews-5mg/" target="_blank"><strong>Bedroom Berry Chews</strong></a></li>
                        <li class="text-magenta" style="display: block"><a href="https://curiowellness.com/product/oh-golden-strawberry-vape-5g/" target="_blank"><strong>OH Vape</strong></a></li>
                        <li class="text-magenta" style="display: block"><a href="https://curiowellness.com/product/golden-strawberry-2-x-0-5g/" target="_blank"><strong>OH Pre Rolls</strong></a></li>
                    </ul>
                    <p style="margin-bottom: 0px;">Look for the OH Intimacy Line at a dispensary near you.</p>
                </div>
                <form action="https://curiowellness.us13.list-manage.com/subscribe/post?u=dece5759ba22ad31873de2487&amp;id=846fcc8a8c" method="post" data-js="conversion-form">
                    <div class="conversion-form__input" style="margin-top: 0">
                        <input class="cta-btn" data-js="conversion-submit" type="submit" value="Find your pleasure"/>
                    </div>
                </form>
           </div>
        </div>
    </div>
`;
