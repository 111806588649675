import { render } from 'lit-html';
import DomUtils from '@/helpers/utilities';
import { page } from '@/templates/page-template';
import { mobilePage } from '@/templates/page-template';
import { isMobile } from '@/helpers/utilities';
import { isMobileSize } from '@/helpers/utilities';

const Page = (() => {
    class Page {
        constructor(node) {
            this.node = document.querySelector(node);
            this.node.utils = new DomUtils(this.node);
        }

        renderPage() {
            if (isMobile || isMobileSize) {
                render(mobilePage, this.node);
            } else {
                render(page, this.node);
            }
        }

        addEventListeners() {
            window.addEventListener('fullReset', render(page, this.node));
        }

        shouldInit() {
            return this.node.utils.exists();
        }

        init() {
            if (!this.shouldInit()) {
                return;
            }
            this.addEventListeners();
            this.renderPage();
        }
    }

    return {
        init({ selector }) {
            return new Page(selector).init();
        },
    };
})();

export default Object.create(Page);
